import React from "react";
import NavMenu from "./NavMenu/NavMenu";

const Header = (props) =>{
    const { current } = props;
    return (
        <div>
            <div style={{backgroundColor:'black'}}>
                <br/>
            </div>
            <NavMenu current={current}/>
        </div>
    )
}

export default Header