import React from "react";

const PostForm = (props) =>{
    const images = document.getElementById("images");
    const videos = document.getElementById("videos");
    console.log(`images: ${images}`);
    console.log(`videos: ${videos}`);
    return (
        <div>
            <h1>
                Post Images
            </h1>
            <input id = "images" type="file" accept="image/png" multiple></input>
            <h1>
                Post Videos
            </h1>
            <input id = "videos" type="file" accept="video/mp4" multiple></input>
        </div>
    )
}

export default PostForm;