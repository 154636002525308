import React, { useEffect, useState } from "react";
import ScheduleBlock from "../Series/Schedule/ScheduleBlock";
import Header from "./../Header/Header"
import { getEpisodesIdsFromSeriesId, getSeriesFromLink } from "../Utils/utils";
import InfoBlock from "./InfoBlock/InfoBlock";
import EpisodesBlock from "./EpisodesBlock/EpisodesBlock";
import { useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import SerieFeedback from "./SerieFeedback/SerieFeedback";

const Serie = (props) =>{
    let { seriesLink } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [series, setSeries] = useState();
    const [episodes, setEpisodes] = useState();
    const fetchData = () => {
        getSeriesFromLink(`${seriesLink}`).then((obj) => {
            setSeries(obj);
            getEpisodesIdsFromSeriesId(obj.id).then((list) => {
                setEpisodes(list);
                setLoading(false);
            });
    })};
    useEffect(()=> fetchData(), []);
    if (isLoading) {
        return (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}>Loading</div>
      );
    }
    return (
        <div>
            <div style={{backgroundColor:'rgb(212,212,212)'}}>
                <Header/>
            </div>
            <div>
                <InfoBlock series={series}/>
            </div>
            <div style={{backgroundColor:'rgb(212,212,212)'}}>
                <EpisodesBlock episodes={episodes}/>
            </div>
            <div>
                <SerieFeedback series={series} episodes={episodes}/>
                <Footer />
            </div>
        </div>
    )
}

export default Serie