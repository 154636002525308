const localhost = 'https://lostbetsandnakeddares.site/'
const LINKS = {
    localhost: {localhost},
    SERIES: {
        getSeriesById: `${localhost}series-handler/api/v1/getSeries/`,
        getSeriesByLink: `${localhost}series-handler/api/v1/getSeriesLink/`,
        getPost: `${localhost}series-handler/api/v1/getPost/`,
    },
    EPISODES: {
        getEpisodeById: `${localhost}series-handler/api/v1/getEpisode/`,
        getEpisodeBySeason: `${localhost}series-handler/api/v1/getEpisodeFromSeason/`,
        getEpisodesFromSeries: `${localhost}series-handler/api/v1/getEpisodesFromSeries/`,
    },
    CATEGORIES: {
        getPostsCategory: `${localhost}series-handler/api/v1/getEpisodesFromCategories/`,
    },
    FORMS: {
        formFooter: `${localhost}form-handler/api/v1/saveSuggestion`,
        seriesFeedback: `${localhost}form-handler/api/v1/saveSeriesFeedback`,
        subscribeForm: `${localhost}form-handler/api/v1/saveSub`,
    }
}

export default LINKS;