import React from "react";
import style from "./ImageLink.css"

const ImageLink = (props) => {
    const {
        imgSrc,
        link,
        alt
    } = props;

    return (
    <div>
        <a target="_blank" href={link}>
            <img alt={alt} fetchpriority="high" src={imgSrc} className="schedule-image"/>
        </a>
    </div>
    );
}

export default ImageLink;