import React from "react";

import bgImg from '../../Utils/images/bannerImage.jpg'
import style from "./Banner.css"

const Banner = (props) => {
    return (
    <div className="banner-image" style={{backgroundImage:`url(${bgImg})`}}>
        <h1 className="center">
            Series
        </h1>
        <br></br>
        <h3 className="center" style={{width:'50%', textAlign:'center'}}>
            My most popular videos get done in different ways, 
            from naked in the park, to naked nightmares, this is 
            the place to look for
        </h3>
    </div>
    )
}

export default Banner;