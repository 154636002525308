import React from "react";
import SlideTitle from "./Utils/SlideTitle";
import SlideVideo from "./Utils/SlideVideo";

import style from "../Styles.css";

export default function Slide({ data: { url, title } }) {
  return (
    <div className="slide-channel">
      <SlideVideo src={url} alt={title} />
      <SlideTitle title={title} />
    </div>
  );
}