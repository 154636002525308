import React, { useState } from "react";

import style from "../../ImageAccordeonHomepage/ImageAccordeonHomepage.css"

const Accordion = (props) => {
    let {
        question,
        answer,
        imgSrc
    } = props;
    const [isActive, setIsActive] = useState(false);
    return (
        <div className="accordionFAQ">
            <div className="accordion-itemFAQ">
                <div className="accordion-titleFAQ" onClick={() => setIsActive(!isActive)}>
                    <div>{question}</div>
                    <div>{isActive ? '-' : '+'}</div>
                </div>
                {isActive && <div className="accordion-contentFAQ">{answer}</div>}
            </div>
        </div>
    )
}

export default Accordion;