import React, { useEffect, useState } from "react";
import Header from "./../Header/Header"
import { getEpisodeFromSeason, getSeriesFromLink } from "../Utils/utils";
import { useParams } from "react-router-dom";
import PostContent from "./PostContent/PostContent";
import style from "./Post.css"
import SideWidget from "../SideWidget/SideWidget";
import Footer from "../Footer/Footer";

function splitSeasonEpisode(seasonEpisode) {
    let list = seasonEpisode.split('_')
    return [list[0], list[1]]
}

const actualSeries = [
    'Race Against Time',
    'Lonely Patient',
    'Mean Celebrity',
];

function isActualSeries(seriesName) {
    return actualSeries.includes(seriesName);
}

const Post = (props) =>{
    let { seriesLink, seasonEpisode } = useParams();
    let [season, numEpisode] = splitSeasonEpisode(seasonEpisode);
    const [isLoadingSerie, setLoadingSerie] = useState(true);
    const [series, setSeries] = useState();
    const [episode, setEpisode] = useState();
    const fetchData = () => {
        getSeriesFromLink(`${seriesLink}`).then((obj) => {
            setSeries(obj);
            getEpisodeFromSeason(season, numEpisode, obj.id).then((epi) => {
                setEpisode(epi);
                setLoadingSerie(false);
            });
    })};
    useEffect(()=> fetchData(), []);
    if (isLoadingSerie) {
        return (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}>Loading</div>
      );
    }
    return (
        <div>
            <div style={{backgroundColor:'rgb(212,212,212)'}}>
                <Header/>
            </div>
            <div style={{display: 'inline-flex'}}>
                <div className="post-content">
                    <p>Published on: {episode.releaseDate}</p>
                    <h1>{episode.name}</h1>
                    <PostContent
                        serie={series.title}
                        episode={episode}
                        actual={isActualSeries(series.title)}
                    />
                </div>
                <SideWidget />
            </div>
            <Footer />
        </div>
    )
}

export default Post