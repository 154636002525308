import React from "react";

import img1 from "../../Utils/images/ImageAccordeon/IA1.jpeg"
import img2 from "../../Utils/images/ImageAccordeon/IA2.png"
import img3 from "../../Utils/images/ImageAccordeon/IA3.jpg"
import style from "./ImageAccordeonHomepage.css"

const ImageAccordeonHomepage = (props) => {
    return (
    <div className="image-accordeon-homepage">
        <img className="accordeon-image-homepage" src={img1}></img>
        <img className="accordeon-image-homepage" src={img2} style={{width:'33%%'}}></img>
        <img className="accordeon-image-homepage" src={img3}></img>
    </div>
    )
}

export default ImageAccordeonHomepage;