import React, { useEffect, useState } from "react";
import { getSeriesFromId } from "./../../../Utils/utils";
import style from "./SeriesBlock.css"

const SeriesBlock = (props) => {
    const {
        seriesId,
    } = props;
    const [isLoading, setLoading] = useState(true);
    const [serie, setSerie] = useState();
    const fetchData = () => {
        getSeriesFromId(seriesId).then((obj) => {
        setSerie(obj);
        setLoading(false);
    })};
    useEffect(()=> fetchData(), []);
    return (
        <div>
            {isLoading ? (
                <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                }}>Loading</div>
            ) : (
            <a target="_blank" href={serie.link}>
                <div className="series-container">
                    <img className="series-image" src={serie.imgSrc}/>
                    
                    <div className="series-info">
                        <h3 className="series-title">
                            <a target="_blank" href={`/${serie.link}`} style={{color:'white'}}>
                                {serie.title}
                            </a>
                        </h3>
                        <p className="series-description">{serie.shortDesc}</p>
                    </div>
                </div>
            </a>
            )}
        </div>
    )
}

export default SeriesBlock;