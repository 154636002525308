import React from "react";

import style from "./NavMenu.css"
import headerImg from "../../Utils/images/headerImage.png"

const NavMenu = (props) => {
    const { current } = props;
    return (
        <div className="topnav" id="myTopnav">
            <div style={{display:'inline-block', marginLeft:'10px'}}>
                <img src={headerImg} style={{width:'3rem', display:'inline-block'}}></img>
                <h1 style={{display:'inline-block', marginLeft:'15px'}}>Nohomo71826</h1>
            </div>
            <div style={{display:'inline-block', marginRight:'20px'}}>
                <a target="_blank" href="/" className={current==="home" ? "active" : ""}>Home</a>
                <a target="_blank" href="/series" className={current==="series" ? "active" : ""}>Series</a>
                <a target="_blank" href="/myblog" className={current==="myblog" ? "active" : ""}>My Blog</a>
                <a target="_blank" href="/about" className={current==="about" ? "active" : ""}>About</a>
                <a target="_blank" href="/roast-me" className={current==="RoastMe" ? "active" : ""}>Roast Me</a>
                <a target="_blank" href="/live-streams" className={current==="LiveStreams" ? "active" : ""}>Live Streams</a>
            </div>
        </div>
    )
}

export default NavMenu;