import React, { useEffect, useState } from "react";

import style from "../../ImageAccordeonHomepage/ImageAccordeonHomepage.css"
import ImageLink from "../../../Series/Schedule/Utils/ImageLink";
import { getSeriesFromId } from "../../../Utils/utils";
//import { getEpisodeFromId } from "../../Utils/utils";

const LatestPost = (props) => {
    let {
        episode
    } = props
    const [isLoading, setLoading] = useState(true);
    const [serie, setSerie] = useState();
    const fetchData = () => {
        if (episode !== null) {
            getSeriesFromId(episode.seriesId).then((ser) => {
                setSerie(ser)
                setLoading(false);
            });
        }
    };
    useEffect(()=> fetchData(), [episode]);
    if (isLoading) {
        return (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}>Loading</div>
      );
    }
    let episodeLink = `${serie.link}/${episode.season}_${episode.numEpisode}`;
    return (
        <div className="latest-posts">
            <div style={{display:'inline-block', width:'45%'}}>
                <ImageLink alt="Not Important" imgSrc={episode.imgSrc} link = {episodeLink} />
            </div>
            <div style={{display:'inline-block', width:'45%', marginLeft:'5%', verticalAlign:'top'}}>
                <a target="_blank" href={episodeLink} style={{color:"black"}}>
                    <h2 style={{fontSize: '2vw'}}>{episode.name}</h2>
                </a>
                <h4 style={{fontSize: '1.3vw'}}>
                    From Series: 
                    <a target="_blank" href={serie.link} style={{color:"black"}}>
                        {serie.title}
                    </a>
                </h4>
                <p style={{fontSize: '1.15vw'}}>{episode.intro}</p>
            </div>

        </div>
    )
}

export default LatestPost;