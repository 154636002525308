import React from "react";

import style from "./../SeriesSlider.css"
import SeriesBlock from "./SeriesBlock";

const SeriesRepeater = (props) => {
    const { seriesIds } = props;
    return (
        <div className="repeater">
            {seriesIds.map(id => { 
                    return (
                        <div className = "repeater-serie">
                            <SeriesBlock seriesId={id}></SeriesBlock>
                        </div>
                )})}
        </div>
    )
}

export default SeriesRepeater;