import React, { useEffect, useState } from "react";
import { getEpisodeFromId, getSeriesFromId } from "./../../../Utils/utils";
import style from "./EpisodeBlock.css"

const EpisodeBlock = (props) => {
    const { episode } = props;
    const [isLoading, setLoading] = useState(true);
    const [serie, setSerie] = useState();
    const fetchData = () => {
        if (episode !== undefined) {
            getSeriesFromId(episode.seriesId).then((obj) => {
                setSerie(obj);
                setLoading(false);
    })}};
    useEffect(()=> fetchData(), [episode]);
    if (isLoading) {
        return (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}>Loading</div>
      );
    }
    return (
        <div>
            <a target="_blank" href={episode.link}>
                <div className="episode-container">
                    <div>
                        <a target="_blank" href={`/${serie.link}/${episode.season}_${episode.numEpisode}`}>
                            <img className="episode-image" src={`../${episode.imgSrc}`}/>
                        </a>
                    </div>
                    
                    
                </div>
                <div className="episode-info">
                    <h3 className="episode-header">
                        <a target="_blank" href={`/${serie.link}/${episode.season}_${episode.numEpisode}`} style={{color:'black'}}>
                            {episode.name} Ep.{episode.numEpisode}
                        </a>
                    </h3>
                    <p className="episode-description">{episode.description}</p>
                </div>
            </a>
        </div>
    )
}

export default EpisodeBlock;