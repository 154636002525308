import React from "react";
import style from "../ImageAccordeonHomepage/ImageAccordeonHomepage.css"
import SubscribeFormShort from "../../SideWidget/Utils/SubscribeFormShort";
import SixImage from "./SixImage";

const SideWidgetHome = (props) =>{
    return (
        <div>
            <SubscribeFormShort />
            <h1 style={{textAlign:'center'}}>
                Leave ANY Requests
            </h1>
            <SixImage/>
        </div>
    );
}

export default SideWidgetHome;