const posts = {
    "Dancing Outside (From afar)": {
        "id": 1,
        "intro":    "This is one of my favorite videos. This wasn't a lost bet, but a dare. After I had gone out, I was dared to stay longer, and do a dance. I don't know how to dance, so the result was bad (You can see for yourself), however it allowed me to have some fun and it actually wasn't embarrassing (Until I saw the result).",
        "imgDescriptions": [
            "As you can see in the image, my first idea of dancing was a jumping jack. And yes, I paused it at that exact moment because it made IT look bigger. (To be fair it wasn't a full erection)"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            13,
            12,
            18
        ]
    },
    "My First Time Outside (Naked in Public)": {
        "id": 2,
        "intro":    "My first video ever made outside (first naked of course). I lost a bet about a soccer match, don't remember which one, with one of my twitter followers (I no longer have a twitter account). I had made a lot of videos inside and he wanted me to do one in public. I was extremely nervous, so he allowed me to just go to a tree outside my house to fulfill it. The video is in slow motion and its still just over a minute long.",
        "imgDescriptions": [
            "Before the video I leave a picture, of when I was running back to turn the camera off and put my clothes on. (It was cold. Don't judge)"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            12,
            18
        ],
    },
    "My first Xvideos request (the making)": {
        "id": 3,
        "intro":    "My first request in the Xvideos comment board I had made peeing videos previously (Most viewed and positively commented on in Xvideos), and I usually struggle (it takes me awhile to start), however after my workout I just couldn't do it. It took me four takes. And some time between them. I struggle to pee when erect. In my first take, I was looking straight at the camera and I couldn't stop thinking about, even though I waited for well over a minute.",
        "imgDescriptions": [
            "As the video was progressing the erection was beginning, so I stopped recording.",
            "After that, I waited a bit to cool off, and tried again. Unfortunately I was unsuccesful again. This time I was so mad I was going to start masturbating, but then remembered that was just going to delay it longer. I leave a picture, and the video as proof.",
            "After this, I drank about 2 liters of water and tried again. This time I was kind of succesful, however the stream started too short (I made a mess of the floor). Also, the video was really short, and as I wanted my first request to be perfect I had to remake it. It is extremely large so you can see the initial stream",
            "After that video I knew I was going to wait a while, as I had just peed, and later forgot to take off my clothes. I liked it, as I thought it would be more natural. What do you think? I was a little harder (erect) than previously, however the stream was perfect and I was so proud. As I took a look at the video though I realized I was too far, as you can see in the image."
        ],
        "numVideos": 4,
        "videoDescriptions": [
            "Here is my first attempt:",
            "Second attempt:",
            "First Succesfull Attempt:",
            "And the best one:"
        ],
        "relatedSeriesIds": [
            8,
            19
        ],
    },
    "Cold Outside (First Picture Story)": {
        "id": 4,
        "intro":    "Sorry, I no longer have the video as it was deleted. As you can see in some images this was from a periscope livestream, which does not allow nudity, so it was taken down and I didn't save it. However I do have these pictures. ",
        "imgDescriptions": [
            "First, let's start with this ones. This was sent to me by one of my followers, without the blur obviously. For me, it was the first time somebody had saved.",
            "I don't know if you can tell but I was dared to rub ice on my penis. It didn't feel good haha.",
            "Then, of course I had to go to my favorite tree and climb it.",
            "Somebody commented on it",
            "Nice butt no?",
            "Like I said, periscope",
            "This picture is one of my favorites ever, as my penis looks bigger than usual.",
            "Finally, I had to see how high I could jump.",
            "and see if I would be able to dunk a basketball.",
            "The answer is no.",
            "Great Picture though",
            "Had to try again",
            "Still no",
            "And again",
            "But still no luck, sadly."
        ],
        "numVideos": 0,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            12,
            18,
            14
        ],
    },
    "Peeing in a 1.5L bottle (and drinking it)": {
        "id": 5,
        "intro":    "Let's set the story. I just woke up and had not drank water since last night. Also, obviously, I had not peed. So... How much of a 1.5L bottle do you think I would be able to fill? And yes, I had to try if it tasted good at the end. Spoiler alert: It did not.",
        "imgDescriptions": [
            "First, let's acknowledge how good my penis looked this morning:",
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            8,
            19
        ],
    },
    "Perfectly Soft (Picture session)": {
        "id": 6,
        "intro": "This intro will be short and sweet. I had a non erect penis, so I took some pictures.",
        "imgDescriptions": [
            "I mean, look at it.",
            "Anyways, after that I decided to do a whole photoshoot",
            "Can you blame me?",
            "I am aware it's not big",
            "But it just looked as good as it could.",
            "How is the view from up here?",
            "And look at those small testicles",
            "I also included some where I was covering it.",
            "Do you like these?",
            "I then took some of my butt.",
            "Like I've always said...",
            "it's the part I feel the most comfortable with.",
            "On this last one, you can see my penis is starting to get hard",
            "And.... I couldn't fight it. It got hard.",
            "I'm so embarrassed. You can't see it.",
            "Okay. Fine. Here it is. But that's not what this post is about so just one",

        ],
        "numVideos": 0,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            21,
            18,
            19
        ],
    },
    "Waking up and getting naked": {
        "id": 6,
        "intro": "There is not much of a story behind this one. I was starting to make videos, and one of my most popular ones was my morning routine (I lost the video). So I decided to make a short one where I just get up from bed and get naked.",
        "imgDescriptions": [
            "Even when I was starting out I had a nice butt, didn't I?",
            "As you can tell, I still was hard by the time I took my underwear off. And yes, I know it's smaller than ideal. :("
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            21,
            18,
            19
        ],
    },
    "First Recording Masturbating": {
        "id": 7,
        "intro":    "Just so you know, I used to masturbate a different way, to say the least, but I will get to that later. Like the title says, this was the first time I recorded myself masturbating, and I was pretty nervous. I did not know how to record it so I just held it, hence the constant moving.",
        "imgDescriptions": [
            "But first, I want to post this picture so that you see I wasn't fat, like some pictures will make me look when I'm sitting down (back to the different way of masturbating).",
            "This is not the strange way of masturbating I was talking about. At the beginning of the video, I start by touching myself to relax a little.",
            "I also don't know why but through the majority of the video I'm covering myself up.",
            "wether it be my penis or my butt, hence the following pictures.",
            "Sorry",
            "But don't worry. There are moments when you can clearly see my butt. P.D.: This has to be one of my favorite pictures of my butt.",
            "I will repeat two things I said before. I was not fat, and I do not know why I kept touching myself. I was just nervous. Anyways, while I was touching my butt, my penis was fully uncovered and on this side view it actually looks long!!! At least in my opinion. Do you agree?",
            "Last one before I get to the masturbation, just because I really love how it looks in this video. Hope you will enjoy it too",
            "Ok, so let's do this by steps. First I go to sit down. (Another great penis picture I think). Yes, I know I didn't have to post it",
            "Once sitting down, I crossed my legs",
            "Once my legs are crossed, then I start leaning forward.",
            "I keep leaning forward until I am CRUSHING my penis, and I start feeling it. Yes, this is really how I used to masturbate. For the video tutorial look below",
            "As a bonus, here is the view from the back (My butt)"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            19,
            21,
            20
        ],
    },
    "Jumping Inside Dare": {
        "id": 8,
        "intro": "This is a casual video in which I simply did some jumps while being naked as I was dared. As you know, I enjoy showing my body, and I am guessing you like seeing it (Otherwise this is not the page for you). That's why I started the video with a little teaser, although I did get naked afterwards.",
        "imgDescriptions": [
            "While the penis teaser worked (the closest I came to actually showing it is in the picture)...",
            "in the butt one I kind of showed it completely.",
            "After the teaser came the jumping. I started with the front one.",
            "I know. It doesn't look that big in this video :(.",
            "Then came the back one.",
            "Personally, I love how my butt looked in this video. Do you agree?",
            "Finally, I don't know why I did this but had to wash my shirt afterwards because I ruined it"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            18,
            22,
            21
        ],
    },
    "Went naked Outside again": {
        "id": 9,
        "intro": "During this dare, I go outside for a little bit longer, although it was only a minute, and there is a little surprise at the end. This time I went without slow motion. Which one do you like better?",
        "imgDescriptions": [
            "Anyways, let me prephase this by saying that there was a plant on the way",
            "although it worked a little bit like blurring.",
            "Of course it didn't blur the whole video, so here are some of my favorites stand stills. On this first one I am aware that I am lacking muscles in my buttocks, but it is still one of my favorites.",
            "Of course you can see my penis, but I cover it mostly throughout as it did not look big that day",
            "And the jump, while it was mostly blurred by the plant does have some great shots like this one.",
            "Again, not big that day so I was covering it for the most part but not here",
            "And what was the little surprise at the end? I started masturbating, although because it was not big, I decided to leave it for your imagination, and showed my ass instead"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            9,
            19,
            6
        ],
    },
    "Ice in underwear (butt version)": {
        "id": 10,
        "intro": "I was dared to put ice inside my underwear and see how long I last. As the video indicates I was in my underwear and not actually naked for a change. This was also for a youtube dare, so the nudity is limited to just the final part (which I didn't post on youtube). It was a fun but slightly painful dare.",
        "imgDescriptions": [
            "As you can see I started by dropping ice inside my underwear.",
            "I then continued to wait until the ice had melted",
            "As you can see, my underwear was very wet",
            "After I've taken the ice off, I pull my underwear down to show you how red my butt was, as it had hurt.",
            "And finally I proceed to take my underwear off to\"show you how wet the underwear is\". As you can see, that is not the only thing that I show."
        ],
        "numVideos": 1,
        "videoDescriptions": [
        ],
        "relatedSeriesIds": [
            14,
            17,
            18
        ],
    },
    "Naked Workout (Picture Session)": {
        "id": 10,
        "intro": "So this are from a video which is aprox. 30 minutes long. It was too long to blur,  but if you like this pictures please let me know so I know it's worth it.",
        "imgDescriptions": [
            "Instead here are some pictures from it.",
            "It was a boxing workout, so I was throwing punches in the air.",
            "These are some of my favorite standstills from the video",
            "I really do love my butt",
            "I was really working it",
            "Part of the workout was to do some squats.",
            "Also squat jumps",
            "I feel like I could make a video just based on squats. Do you agree?",
            "Of course, when I got too tired I had to go look at my phone how much time it was left",
            "Up close look",
            "I then went to the ground and did some push ups.",
            "And then a plank.",
            "Then I did a plank but switching arms touching my chest",
            "I did some bicycle kicks.",
            "And then I finally got to rest a little bit",
            "Then, I finished with some plank wraps",
            "I was starting to get hard."
        ],
        "numVideos": 0,
        "videoDescriptions": [
        ],
        "relatedSeriesIds": [
            22,
            20,
            18
        ],
    },
    "Feeling myself": {
        "id": 11,
        "intro":    "This isn't a dare or a lost bet. It is a video where I record myself touching and just feeling around. It was one of my favorite videos to make, as I could just do what I wanted. I start the video off with clothes, and then I take them off.",
        "imgDescriptions": [
            " You can see that I was soft, because when I raised my leg you could se the tip",
            "Then, I went down into a weird pushup/plank position with my legs crossed. When I first posted this video on twitter, it was actually without the blurring. But I lost those videos with my original account and all I have now is this.",
            "Working both sides",
            "I then stood up, and went down like this, for a special penis show as you will see",
            "I start getting up...",
            "And there it is",
            "Then as I turned to the other side, I hit my penis with my leg on accident. OW.",
            "Then I used to my advantage that my penis never got hard during the video and put it between my legs.",
            "Obviously I had to do the same thing but from the back.",
            "Final note, I know it isn't big, but at least is big enough to be seen from behind"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            19,
            20,
            21
        ],
    },
    "Run naked in street dare": {
        "id": 12,
        "intro": "This was a dare that I did after a lost bet. I had to run naked into the street. It was the first time I went to a place I could actually get caught, not my yard, which is why I did it at night. I went on to do this type of dare four times, three of which were like this. Unfortunately, I lost the other two. You can however find a censored version of this video on youtube, in the link https://www.youtube.com/watch?v=WKWYk8h2Yds. P.D.: I didn't censored my face. The uncensored video is here, although my face is replaced by my butt.",
        "imgDescriptions": [
            "Although it is a short video, you can see my butt before I get lost in the dark.",
            "I was so nervous",
            "And then, I come back in and do a little jumping.",
            "From both sides",
            "And at the end, you can see my penis. I couldn't resist the adrenaline and it got hard"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            12,
            6,
            7
        ],
    },
    "Shower part 2": {
        "id": 13,
        "intro": "I took a shower, and decided to record myself. The video is recorded in two parts. I will post the second part later. There isn't much description needed, except that by this point I was already showered and was just feeling myself in the water.",
        "imgDescriptions": [
            "This led to some, in my opinion, awesome moments, such as this, when I open up my butt for you to see.",
            "Of course, I was hard at this point, but I still enjoyed playing with it, and it didn't look as small in the video as I saw it live.",
            "One of my favorite pictures!! I just love my butt. I guess it's because there isn't a size expectation",
            "Okay. From this angle it does look a little small. Nevertheless, I thought it was a great picture, just don't laugh, or do it's not like I'm going to see."
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            20,
            8,
            16
        ],
    },
    "Naked in the park Part 2": {
        "id": 14,
        "intro": "This was obviously a lot riskier, even if I went in the morning, and I was actually seen, which is why I had to run at the end. I started this part at the games, and I went to the one where you have to go across it.",
        "imgDescriptions": [
            "Here I am swinging.",
            "I thought I heard somebody, but not yet",
            "I went closer to the camera just in case",
            "Then went a little farther",
            "Sexy pose time.",
            "And... got caught",
            "Running from the situation"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            6,
            12,
            7
        ],
    },
    "Toothpaste on penis dare": {
        "id": 15,
        "intro": "I was dared by a \"fan\" to put toothpaste on my penis. Needless to say, it hurt. This video was for youtube originally, so the nudity is kept to the minimum. Nonetheless there are some clear shots. However I won't hide the fact that the focus of this video is on my suffering and not on my body. Should I focus on more videos like this?",
        "imgDescriptions": [
            "Anyways, here is me putting toothpaste on my finger",
            "And now on my penis",
            "Probably shouldn't have chosen mint toothpaste. It didn't feel so fresh",
            "It actually did hurt a lot",
            "I was trying to walk it off but that was unsuccessful."
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            17,
            14,
            11
        ],
    },
    "Untold Story of Dare Dancing Outside": {
        "id": 16,
        "intro": "I was dared to do a little dancing outside my home, naked of course, as I already have posted about. Although it sounded simple, it was really tough to do because there were no walls to prevent the neighbors from seeing, and a neighbor that had seen me in a previous dare threatened me with either telling him when I was going to do another dare so he could see, or if he caught me again he would tell my parents. So this was the first time I did this with somebody actually physically watching, so I was actually really embarrassed. After the video, he just told me to tell him for the next one again, and that he really liked me. Although he was a creep, at least he helped me feel more comfortable at the end.",
        "imgDescriptions": [
            "Anyways, here I start running towards the bird feeder, as my neighbor told me that was the best spot.",
            "As you can tell, at first I was so embarrassed and didn't want to show anything",
            "I turned around because I thought it could be easier to start with my butt",
            "But I just decided to get it over with and move on to some jumping jacks (yes I know that's not dancing)",
            "Turned around and did the same",
            "I thought I heard somebody but it was just the leaves with the wind.",
            "So back to dancing. Shake your groove thing",
            "I did the helicopter",
            "And the walk of shame, going back near my neighbor, covering my penis, so \"he can't see it\". Obviously after the video ended he made me take my hand off and recorded me while I put my clothes on. What did he do with that video, nobody knows, but at least he kept complementing me, and made me feel a lot more secure."
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            13,
            6,
            12
        ],
    },
    "Ice in underwear Butt Second Time": {
        "id": 17,
        "intro": "I did it again. The ice was colder, and it was a lot more painful, hence I didn't last as long. Sorry, but you can see how red my butt ends. Anyways, I lasted more than a minute so it was still a win for me. The challenge is simple, put ice in my underwear for at least a minute.",
        "imgDescriptions": [
            "Here are my favorite snapshots from the video. First,  proof that I was putting ice in",
            "It looks liked I pooped",
            "Again, Real ice.",
            "Had to take a short break, but you can tell the ice was melting",
            "Really wet underwear",
            "And a wet butt. I took my underwear at the end because I couldn't take it anymore."
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            14,
            17,
            10
        ],
    },
    "Ice in underwear Penis": {
        "id": 18,
        "intro": "Ok. THAT was painful. I tried the ice in underwear dare, this time on my penis. SO much more painful. I barely lasted a minute, but again the rules are just about a minute. I do not recommend you try this.",
        "imgDescriptions": [
            "Anyways, here are my favorite pictures from the video. First, had to put the ice in",
            "The ice was there, and my penis was wet",
            "Big bulge, definitely not because of the ice",
            "Ok fine, it was",
            "Tried to dry it off, because it was so painful",
            "Yes, I got hard.",
            "Finally took it off. It was SO cold"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            14,
            17,
            10
        ],
    },
    "Naked in Park (Part 4)": {
        "id": 19,
        "intro": "This time, I went to the park during the day, and curiously, I wasn't caught. I was so scared I couldn't even get hard though, and I didn't fully get rid of my clothes in case of an emergency. During this part I go down the stairs, and leave nothing to the imagination.",
        "imgDescriptions": [
            "Here I am going down the stairs",
            "Stopped there to enjoy myself",
            "Like I said, I show everything",
            "...and couldn't get hard",
            "Some people really like this, so I'll leave it here"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            6,
            12,
            19
        ],
    },
    "Spanking": {
        "id": 20,
        "intro": "It didn't hurt at first, but the more I did it, the more it hurt. My butt ended up SO red, and it lasted so long that for my next video, a wedgie one it was still red. I'm not going to lie though,I enjoyed it. For the next one I was thinking about spanking me with some objects. What objects should I use? Anyways, to the pictures.",
        "imgDescriptions": [
            "It started ok...",
            "It vibrated, I was going hard at first",
            "Had to take a break so decided to show you guys something",
            "Up close",
            "It was really red",
            "And proof that it lasted for my next video"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            11,
            10,
            9
        ],
    },
    "Wedgie": {
        "id": 21,
        "intro": "I had to do it. I apologize for the red butt, I recorded it after a spanking video. Anyways, I break my underwear doing this wedgie. It hurt more than it looked, but I'm not going to lie, I enjoyed it.",
        "imgDescriptions": [
            "Some of my favorite snapshots are this ones. First, it did get in there.",
            "Did it on the front too.",
            "By now I was getting serious, pushing down and pulling up",
            "Tried something different with my penis outside my underwear, but it was less efective",
            "Nonetheless, it looked good from the back",
            "It's almost as if I don't even have an underwear",
            "Took my underwear off",
            "And showed you my butthole in 4k"
        ],
        "numVideos": 1,
        "videoDescriptions": [
            
        ],
        "relatedSeriesIds": [
            10,
            11,
            9
        ],
    },
    "Naked Nightmare Challenge (First website Request)": {
        "id": 22,
        "intro":  " So, I don't know who it was, but somebody requested the naked Nightmare Challenge through this website. Thank you to whomever it was, and I hope I don't dissapoint. It was the longest I have ever spent producing a video. I wasnt sure what it was at first, but after looking at several videos about it, this is what I came up with. Please feel free to comment, leave any new requests, or send me pictures, I love that too.",
        "imgDescriptions": [
            "So, from what I got, it was a recreation of a nightmare, in which you are doing a tutorial about something basic, in my case a workout, and you slowly lose your clothes without realizing it... until you do. If that is not what it was, please let me know. ",
            "Anyways I started my workout tutorial with some stretches, in a program narrated by two women, Salli and Kendra, in front of a live audience, fully clothed.",
            "But by the time I got to high knees, I had already lost my shorts, albeit I didn't realize it.",
            "When I started doing arm circles, I lost my shirt as well",
            "I even started boxing a little not realizing it.",
            "But after I was done with the arm circles I was completely naked, with the audience laughing",
            "I didn't know what it was about so I kept going, doing some jumping jacks. By this point, one of the female announcers, Salli was already making fun of me and the size, while Kendra felt sorry for me.",
            "Still not realizing I was naked, I turned around to do some situps, to nobody's delight.",
            "After I went to finish the stretching part, by touching my toes, but that's when I realized I was completely naked. I was so embarrassed.",
            "The audience was laughing, the announcers were making fun of me, and I didn't know where to go.",
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            1,
            2,
            3,
        ],
    },
    "Naked in Park (Part 5)": {
        "id": 23,
        "intro": "This time, again, I went to the park during the day, and was almost seen, but left on time, and without needing to pull up my shorts. I didn't fully get rid of my clothes in case of an emergency. During this part I play on some of the games and then leave",
        "imgDescriptions": [
            "Here I am getting ready to try",
            "I did it",
            "I was going to go the other way but changed my ming. Normally during the side view, my penis looks bigger, but this time it wasn't the case, as I was struggling to get hard",
            "I even tried masturbating, but it didn't work.",
            "Some early joggers were coming so I had to hide, giving you this closeup albeit it is soft"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            6,
            12,
            18
        ],
    },
    "Lost towel Locked outside": {
        "id": 24,
        "intro": "So here is the situation. I was getting ready to shower, when somebody knocked on my door. I put on a towel, and go see who it was. As I was closing the door, for security reasons, my towel got stuck. At the same time I realized I didn't have my keys. In what pocket would they be if all I had was a towel? I desperately try to open the door, or at least get back my towel, but I keep failing on both. Until finally I am able to recover my towel and cover myself up again. And no, I never found out who was knocking on my door.",
        "imgDescriptions": [
            "Of course, this was a request on my website, so whomever requested it, I hope you enjoy",
            "Like I said, it started normally, getting ready to shower",
            "My towel fell off the first time I put it on, but I was able to recover before I went outside",
            "Until it got stuck",
            "You will never know the embarrassment that I was going through",
            "I decided to just focus on getting the towel back, and eventually succeeded."
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            7,
            5,
            9
        ],
    },
    "Full Monty Strip Dance": {
        "id": 25,
        "intro": "I am too young to have watched this movie, but I did some research and I hope I make whomever requested it proud. This was a little bit different to the ones I normally do, as I had to tease and not just strip. To be honest it felt weird, and I wasn't sure what I was doing. Nevertheless I gave it my best try, so I hope you enjoy. If you have any tips on how to improve, or new requests, please let me know.",
        "imgDescriptions": [
            "Obviously, the dance starts with full clothes and so I started clapping, again, no idea what to do.",
            "My shirt off. During this one, I felt like a stripper",
            "Got up close, and teased a little bit with my underwear",
            "And shook what my mama gave me, after I took it off",
            "And a little more dancing.",
            "I know the point of the strip tease is to not show anything, but like I said, it was my first time, so I forgot",
            "I did remember though",
            "You can show the butt on a striptease right?",
            "Cover",
            "And uncover"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            13,
            19,
            20
        ],
    },
    "Naked in the park (part 7)": {
        "id": 26,
        "intro": "This time, I did not use any games, but I stood next to the swings and masturbated. After a few scares of almost getting caught, I was really nervous throughout, but I kept coming, no pun intended. However, I was not going to risk it all, so decided to not take my clothes completely off.",
        "imgDescriptions": [
            "Like I said, after surveying the area, I immediately started masturbating.",
            "You get the views from both sides",
            "Looking back at it, it looks big in here doesn't it?",
            "After I was done, I immediately pulled up my pants, and you will never understand the regret and embarrassment I was going through. Hence, the last time I did this."
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            6,
            12,
            19
        ],
    },
    "Shower Orgasm Torture Dare": {
        "id": 27,
        "intro": "Sorry for the delay in posting. It took me a while to recover and to want to relieve this moment. I'll start by explaining what the dare was. This was one of the dares in my inbox, from all the way back in Nov.23. Yes I know, I'm really behind, maybe I need to start posting more often. Keep leaving me dares and I will, next one is coming on Sunday.",
        "imgDescriptions": [
            "Anyways, the dare was to \"do this\", and a link to a webpage.",
            "The webpage was this blog with the Shower Orgasm Dare. You can read it, but in summary it is to hold the shower head either close to your penis' head or where it is but pointing at your head, for x amount of time, which is determined by a dice",
            "And a little bit more info",
            "So I started by rolling the dice, and it was unfortunately a 5, which meant 10 minutes. Tried to reroll and still got a 5",
            "So I set the timer",
            "I was actually soft when it started which made it harder to convince me to do it",
            "Soft and small. Sorry to disappoint. As I didn't have a shower head, I used a sink and a cut up water bottle to concentrate the water on my penis head",
            "It didn't take me long before I started to get hard, and the torture began. It was constantly being stimulated, but sometimes I got soft and sometimes hard, but it felt really weird",
            "I tried a little bit of the hand job to stay hard, but it felt really really bad, so I had to keep a balance",
            "Eventually, I did make it through the 10 minutes, with no ejaculation. I suffered a lot nonetheless. But that's not good enough so...",
            "I started masturbating to get me on the edge",
            "And went again. This time I was as hard as I can be. I know it is still small. Sorry",
            "But this time I didn't last a minute, and this was the worst feeling I have ever had. I felt like I wanted to ejaculate more but couldn't as I was getting stimulated. One of the worst feeling in my life"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            17,
            19,
            14
        ],
    },
    "Naked in the park (part 1)": {
        "id": 28,
        "intro": "During this part, it was at night, so I took some more risk, taking my clothes completely off. Then I went to cliumb a little game, and came back. It's a little short, but like I said, it was the first part so I was really nervous.",
        "imgDescriptions": [
            "To the pictures. I start with all my clothes, disguised as a runner",
            "But as soon as I know there's nobody else there, I take them off",
            "I get up close to the camera and then start my way to the game. Look at my butt though",
            "I then proceed to climb the game",
            "and even do a little masturbation, but no happy ending",
            "Gave you this magnificent view though",
            "and a closeup at the end"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            6,
            12,
            19
        ],
    },
    "Peeing Outside (and bonus inside)": {
        "id": 29,
        "intro": "So, these is a small collection of my small penis peeing. One of them, I am outside, and the other one I'm inside. This are some of the toughest videos to make, because I need to stay soft, but I manage.There isn't much more to say this time, but if you like this type of videos I can make more, just let me know. Enjoy the pictures",
        "imgDescriptions": [
            "First, my stream outside",
            "I was starting to get hard",
            "And inside. Soft, I know"
        ],
        "numVideos": 2,
        "videoDescriptions": [
            "Here is me peeing outside",
            "And the bonus video inside"
        ],
        "relatedSeriesIds": [
            8,
            12,
            19
        ],
    },
    "Naked Nightmare (Christmas Magic Edition)": {
        "id": 30,
        "intro": "Christmas is a special time of the year, full of magic. For this, I created a special edition of the Naked Nightmare Challenge. I know I'm late by a day, but it took really long to edit. I wanted to do something special, and this was by far my most popular video. Without further ado, the challenge: If you are just interested in the video, feel free to just scroll down. Otherwise I'll tell you what it is about, with some pictures. This time, Sally and Kendra were hosting a magic show, where the contestant, me, went and did some magic tricks live in front of an audience.",
        "imgDescriptions": [
            "Of course, like any magician, I was clothed.",
            "But before I could finish my magic trick, my pants had disappeared. Of course, I didn't notice and just kept going. To the delight of the announcers...",
            "Specially when I got closer.",
            "But all of that came to an end as soon as I lost my underwear. When they saw the size, both announcers started laughing at me, and feeling sorry. Unlike most of the other videos, I successfully tried to avoid an erection, so you can see how small it really is.",
            "Finally, I lost my shirt, and although I noticed I lost my shirt, as I thought it was just my shirt I kept going.",
            "Kendra, feeling sorry for me tried to get a closeup so my penis looked bigger. As you can see, she failed.",
            "Sally, thinking it was funny asked for an even closer look. And no, that is not as small as it gets, but it is the size it usually is. After I workout it can get even smaller. But back to the video, after this, Sally and Kendra started interviewing people from the audience.",
            "Finally, after about 10 minutes, I noticed.",
            "I was told that I would be given a towel under one condition...",
            "That I waved my two hands to the audience, by then with half an erection",
            "After that, I was finally given a towel"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            1,
            4,
            2
        ],
    },
    "Stolen Clothes/Towel and Locked Outside": {
        "id": 31,
        "intro": "This was a risky video to make, as it involved me going fairly far outside my house, in order to retrieve my stolen clothes. I actually got caught, but fortunately, the person that saw me was probably more embarrassed than me, and just looked the other way and kept walking. For me, there was just nothing I could do, even if I did try to hide. But to the video, it is pretty self explanatory. I go to take a shower, and everything gets stolen, so I have to go outside my house to retrieve them, but I close the door and get locked.",
        "imgDescriptions": [
            "The video starts with me going into the bathroom after a workout to take a shower.",
            "As I am taking off my clothes you can see my small penis",
            "Then, I get in the shower",
            "But somebody comes in and steals my clothes, and throws them fairly far away.",
            "As I haven't noticed, my penis is still small, unfortunately.",
            "As I notice what happened, and realize what I'm going to have to do, I get a semi erection",
            "I spend the next few minutes (shorten in the video), trying to dry myself, anyway possible and trying to get encouraged",
            "I finally gain the guts to do it, and start making sure nobody is at home.",
            "Then I look outside, so I don't get caught",
            "I quickly run to look for my clothes. Unfortunately, even one hand was enough to cover my penis",
            "I also do need to workout my butt, this does not have the muscle required",
            "Like I said at the beginning, as I was going down the stairs, somebody saw me through the window, so I tried to go back up, but when she saw I saw her, she just turned the other way around, and kept walking.",
            "So I went to make sure, and then retrieved my clothes",
            "As I was going back to my house, I realized it was locked. And once again, disappointing butt.",
            "Luckily, I was able to put on my clothes and go ask for help"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            5,
            7,
            9
        ],
    },
    "Naked in the park (part 3)": {
        "id": 32,
        "intro": "During this part, I was masturbating in the early morning. Don't judge, it was really cold, so it took me a lot longer than it should. Anyways, to the pictures.",
        "imgDescriptions": [
            "I start with a beautiful view of my butt, although it does hide my penis",
            "But not for long, as I turn around for you to see",
            "I decided to take some risks and take a little walk a little farther away from the camera. You're welcome for the view",
            "But at the end I came back closer to finish it off. Yes that is cum coming out of my finger"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            6,
            19,
            12
        ],
    },
    "Pantsed in front of boss": {
        "id": 33,
        "intro": "I get pantsed in front of my boss and eventually get fired. As I get called into the office, I am delayed and get there late. With my boss already mad, I go to get his notebook, but as I'm about to give it to him, Joanna pantsed me right in front of the boss. My boss, annoyed, then asks me to complete some tasks in order to \"keep my job\", but ends up firing me anyways",
        "imgDescriptions": [
            "As I said, as I was about to give him the notebook, I got pantsed.",
            "The boss then asked me to leave my pants and underwear behind, and get closer. By this time I had forgotten about the notebook",
            "He then ordered me to take off my shirt. Personally, I love this view. My penis looked great in this video.",
            "I was then finally reminded of the notebook, and picked it up to give it to him",
            "He wasn't done though. He ordered me to spin around for the whole office, as I had made them all look bad. After, I was allowed to leave, but still got fired"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            9,
            10,
            11
        ],
    },
    "Peeing and drinking it": {
        "id": 34,
        "intro": "I pee in a bottle, and then drink it. There isn't much of a story behind it. I was dared to drink my own. pee again, and so I did. It did not taste good at all",
        "imgDescriptions": [
            "First of all, I was semi erect and it looks so much bigger than normal",
            "But I was still able to pee on it.",
            "It tasted so bad, I hated this part",
            "And I don't know what was going on here in the meantime"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            8,
            19,
            15
        ],
    },
    "Wedgie and Locked Outside": {
        "id": 35,
        "intro": "I'm back with a request. This time the request was to get a ripping wedgie, and then have to go back home. Of course, I closed the door and got locked out. Unfortunately for me, I didn't have my keys, obviously, and had to stay out. As my landlord, Joey, hated me, I was too scared to ask him and ended up being seen by a mother and his family, The mother obviously furious, told me that I had five minutes to get Joey to open my door. And so I did, turns out he had a little crush on me.",
        "imgDescriptions": [
            "Here is the request",
            "Of course, it was a wedgie from both sides. The front...",
            "and the back.",
            "I finally was able to rip it, and yes,I had an erection. Do you like it? Or should I remain soft for my videos? Let me know.",
            "The moment I realized I was locked out.",
            "And the moment the mother found me.",
            "Thank you Joey for finally ending the torture"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            10,
            7,
            5
        ],
    },
    "Naked Nightmare (Yoga Edition)": {
        "id": 36,
        "intro": "It is finally here! Naked Nightmare Yoga edition leaves no part of me left unseen! Sadly, I realize too late, and the announcers, Sally and Kendra, make me dance before they give me a towel. This will probably be my last video over 10 minutes as they require a lot of editing, so I hope you enjoy.",
        "imgDescriptions": [
            "Obviously, I start with all of my clothes",
            "And then, my shorts disappear. And I am wearing a little short underwear, so you get a nice preview.",
            "Next, I lose my short, and now my whole butt is revealed in front of the audience",
            "And not just my butt…",
            "Like I said at the beginning, Yoga really leaves nothing left unseen",
            "And of course, I finally lost my shirt. I had to take breaks between the takes to keep my penis that soft for you. You can barely see it past my bush, as the announcers kindly say in the video.",
            "Of course, I had to zoom in and you can clearly see how small it is.",
            "My butt on the other side…",
            "As I open my eyes and realize, I am forced to get on my knees and turn around, so I try to harden my penis up, much to the dislike of the announcers",
            "After my first two failed dance attempts fail to impress I am forced to do the Macarena leaving my erect penis fully uncovered, before finally getting my towel and leaving"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            1,
            13,
            4
        ],
    },
    "Twerking (First Try)": {
        "id": 37,
        "intro": "I had never done this before, but after practicing for some time, this is what I came up with. There are three videos in this story, one from behind, one from the side, and one where I twerk on the wall, my favorite. Although my ass is usually my strong suit, I cannot deny that my ass is a little bit small to twerk, specially as a beginner.",
        "imgDescriptions": [
            "Nevertheless, it was a request.",
            "First, I will start with the side one, as it is my least favorite. It did however, produce one of my favorite penis shots",
            "Otherwise, very inconsequential.",
            "The one from the back? Much better view. Here it goes down",
            "And it goes up, but it is better on the video",
            "I have very small testicles haha",
            "And my favorite, twerking on the wall, I'm just showing off here",
            "Down",
            "Up.",
            "By now I was having so much fun, my penis went back to its normal size haha"
        ],
        "numVideos": 3,
        "videoDescriptions": [
            "From the side:",
            "From the back:",
            "And on the wall:"
        ],
        "relatedSeriesIds": [
            13,
            18,
            21
        ],
    },
    "Naked in the Park part 6": {
        "id": 38,
        "intro": "I go to the park during the day, and obviously take my shorts and underwear down, as well as take off my shirt. I start off trying to make it a little bit erect, before going to climb the game from both sides. While I was lucky the park was empty when I got there, at the end of the video somebody was coming, so I had to run and hide before they saw me.",
        "imgDescriptions": [
            "But down to the pictures, like I said, first I wanted to make it a little erect",
            "And I think I dropped something...Oops",
            "Anyways, climbed this side and everything looks good",
            "Same from this side",
            "Oh no, they're coming",
            "This is what the first picture was for. Did I do a good job?"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            6,
            12,
            7
        ],
    },
    "Spanking with Yellow Band": {
        "id": 39,
        "intro": "I deserve the worst in this videos, as I punish myself with yellow resistance bands, until my butt ends up red. This is not me standing, I'm leaning over, to try and make it more painful. It also give you a different view. As you can probably expect, this hurt, however the place where it hurt the most was my buttcrack, as the band kept hitting me there.",
        "imgDescriptions": [
            "There isn't much to put in pictures, or story to tell, so I'll just give a short description and move on to the videos. Look at this position",
            "Right on the spot",
            "I had to switch location as it was not allowing me to use my full strength. Look at this much better hit",
            "It ended up SO red."
        ],
        "numVideos": 2,
        "videoDescriptions": [
            "Here is the first video:",
            "And the one at the better location:"
        ],
        "relatedSeriesIds": [
            11,
            10,
            14
        ],
    },
    "Broken Wedgie And Feeling Myself": {
        "id": 40,
        "intro": "I go through the pain of breaking my underwear, so naturally I start touching myself to deal with it. This video is a little bit shorter, but it has substance. It also has a special meaning, as it is the last video I have from my old xvideos account. From now on out, every video will be never before seen. But back to the video, for some reason, the pain turned me on and I was dealing with a full blown erection. Watch for yourself.",
        "imgDescriptions": [
            "Like I said, I start with a wedgie, as it gets broken",
            "Then I take it off and leave you with this view",
            "Like I said, full erection from the pain",
            "And I touch myself, enjoying my body."
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            10,
            11,
            19
        ],
    },
    "Receiving Amazon Package Naked": {
        "id": 41,
        "intro": "So, I lost a bet. As punishment, I had to receive my package from Amazon completely naked. I was extremely nervous, as I did not know how the driver was going to react, but luckily, he was a man, and he understood. Not without getting scared first, and forcing me to leave him a good review.",
        "imgDescriptions": [
            "To the pictures, the video starts with me walking around nervously.",
            "You can see me from both sides",
            "Then he finally gets here",
            "I was so nervous, but this is a great shot",
            "I finally dared to open the door, this is what the driver saw",
            "And of course he reacted by dropping my package, I couldn't leave him a bad review though, as that would mean he would report me for opening naked.",
            "Then I waited for him to leave before picking up my package",
            "And finally I picked it up"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            7,
            5,
            4
        ],
    },
    "The Interview": {
        "id": 42,
        "intro": " In this episode, we are introduced to nohomo71826 through a TV interview. The problem? Every time he insults the host of the TV show one article of clothing disappears. The TV host, Joey, knowing he has gold in his hands keeps the interview going and even encourages nohomo71826 to keep insulting him. Nohomo71826 obliges and ends up naked. By the time the host lets him know about it, it is too late, and even though Nohomo71826 tries to scare him off posting the interview, the host decides to go through it. What will the ramifications of that will be? Check the next episode.",
        "imgDescriptions": [
            "Let's start with the host, calmly sitting in his chair.",
            "And of course, the mean celebrity",
            "And he just called somebody an idiot, so shirt off",
            "He really needs to calm down now.",
            "And he didn't. Kept it soft for you.",
            "And F U to everybody thats profiting of these interview",
            "At least I have a nice butt no?"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            2,
            1,
            4
        ],
    },
    "Eating from Body": {
        "id": 43,
        "intro": " For this one, it is really simple. I place some food in my penis, and let it rest for a while before eating it. Then, I place it on my butt and do the same. Did I like it? It tasted about the same, but it definitely turned me on a little bit.",
        "imgDescriptions": [
            "To the pictures, I started the video soft...",
            "But I didn't last long before I started to get turned on, but look at all that food.",
            "And look at that butt",
            "And that butt with food",
            "Yeah, by now I was erect"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            15,
            8,
            16
        ],
    },
    "Feeling Bad": {
        "id": 44,
        "intro": "In this episode, I go to the doctor, as I am dealing with a headache. The doctor, worried, asks me to take my clothes off to do a further examination. In order to help me feel more comfortable he takes them off two. Unfortunately, the doctor was right, and I have a very bad disease. The doctor says that I will need further examinations, but that for now, all I can do is go home, workout, and be with loved ones. The problem? I don't have loved ones. How am I going to deal with it? See for yourself in episode 2.",
        "imgDescriptions": [
            "Back to this episode, like I said, the doctor asks me to take off my shirt, and he takes it off as I was uncomfortable.",
            "I then take it off as well",
            "He then tells me he needs to inspect everything on my body",
            "After some reluctance, I finally agree",
            "Finally he asks me to get into yoga pose, in order to inspect my butt, so I obligue",
            "The doctor, worried about me, gets confused and puts on my clothes, forcing me to put his on, but I am so confused and worried I don't even care"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            3,
            21,
            22
        ],
    },
    "The Witch": {
        "id": 45,
        "intro": "The episode begins with Nohomo71826 trying to talk his friends, Joey and Kimberly, out of a lost bet punishment, in which he has to go to the park naked. Kimberly's little brother, pressures him and calls him a chicken, which is made worse, as is later revealed Nohomo71826 has a crush on Kimberly, although that feeling is not reciprocal. Finally getting the courage to go to the park, Nohomo71826 finds a lady which gets mad at him for being naked. After refusing to go get some clothes, as the bet wasn't over, the lady claims to have cursed him. Although Nohomo71826 does not believe her, a little girl tells him its true and she made her dog disappear. Still skeptical, Nohomo71826 finally goes back home, where he explains to his friends what happened, and makes fun of the witch. Is Nohomo71826 actually cursed? See the next episodes to find out.",
        "imgDescriptions": [
            "To the pictures, although I try to talk them out of it, after I am called a chicken by my crush's little brother I have no choice but to do it.",
            "I am told to turn around, so they can see \"two new sides of me\".",
            "Afterwards I finally go to the park and I find a witch (not in picture)",
            "After she tells me to put clothes on, I turn to my friends to see if I am allowed, but I was not.",
            "After the witch curses me, I say whatever and start to leave, but when I turn around there's a little girl there. Scaring me, and making my humiliation worse. She warns me she is a real witch but I don't believe her",
            "As I get back home and I start telling them what happened, there are some great penis shots, including some bouncing on slow motion",
            "And of course, before I put my clothes back on, this awesome shot"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            4,
            1,
            6
        ],
    },
    "Eating Poop From My Hand": {
        "id": 46,
        "intro": "This video is very self explanatory. I poop, and then grab a piece with my hand and eat it. If you are curious, it tasted awful, and I couldn't finish it. I know I end the video a little early, but trust me, it is for your own good, as I just started puking after.",
        "imgDescriptions": [
            "Anyways, to the pictures. Yes, I did poop, here is the proof",
            "I was so nervous, and felt so embarrassed by what I was doing I couldn't stop touching my penis",
            "Even if it is blurred, I did eat it",
            "Here is the piece."
        ],
        "numVideos": 2,
        "videoDescriptions": [
            "The pooping:",
            "The eating:"
        ],
        "relatedSeriesIds": [
            16,
            8,
            15
        ],
    },
    "Naked Nightmare Concert": {
        "id": 47,
        "intro": "In this episode, Nohomo71826 is a rockstar, and we are watching a livestream of one of his concerts, narrated of course, by the duo of Sally and Kendra. If this is your first time watching, this series consists of my clothes just disappearing magically one by one, while I am doing a public activity, in this case a concert. In a twist, this time Sally and Kendra are not in the concert, so I am never able to hear them, and they don't criticize me.",
        "imgDescriptions": [
            "Anyways, to the pictures, as always, I start with all of my clothes on giving a normal concert:",
            "But my shorts are the first to disappear",
            "Followed of course by my underwear leaving my butt and penis exposed for a long time.",
            "After that, I lost my shirt, but at least I still had my guitar",
            "Or not. As my guitar disappears I finally notice.",
            "Obviously, I get extremely embarrassed and try to cover it, wondering why my band didn't stop me.",
            "Finally, I decide to just not care and \"rock on\""
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            1,
            4,
            2
        ],
    },
    "Breaking News": {
        "id": 48,
        "intro": "After the interview from the last episode went viral, and is on every news outlet, Nohomo71826 goes to his agent house to yell at him, in front of his family. As he starts insulting him, his clothes once again start disappearing, one by one, leaving him naked once again without him knowing. As the agent is mad at him, he lets him continue and just record him, until his daughter and sons make fun of Nohomo71826 leading him to realize it. The agent insinuates that he will post the video as he can make a lot of money with it.",
        "imgDescriptions": [
            "To the pictures. The video starts with a recap of the interview. As it is on the news, it has to be blurred obviously.",
            "Then, we move on to Nohomo71826 at the agent's house.",
            "First insult, and goodbye shirt",
            "Second one, and goodbye shorts",
            "Third, and there goes my underwear",
            "As my agent realizes, he starts recording me",
            "When his daughter asks a question, and tells me to look down, I realize I am naked"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            2,
            1,
            4
        ],
    },
    "The Realization": {
        "id": 49,
        "intro": "In this episode, as I get back home after my doctor's exam, I am destroyed by the diagnostic. Depressed and alone, I take my clothes off, as the reality really starts to hit me.",
        "imgDescriptions": [
            "To the pictures, I get naked while looking at myself in the mirror",
            "I didn't know how to deal with it.",
            "Being stripped of my clothes didn't make me feel better, but that butt...",
            "hole",
            "I lay down trying to come to grips with it",
            "But to no avail",
            "Finally, I pick myself back up"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            3,
            19,
            21
        ],
    },
    "Stolen Clothes in Office": {
        "id": 52,
        "intro": "After a workout in my office gym, I go take a shower. To my surprise, when I get out of the shower, my clothes are stolen, and I have to go through the office to retrieve them. I never found out who was responsible for this though.",
        "imgDescriptions": [
            "The first picture is of me in the shower",
            "My unaware ass didn't know my clothes had gotten stolen",
            "I was trying to go when nobody was looking",
            "And I failed",
            "I was so nervous",
            "But finally got them back"
        ],
        "numVideos": 1,
        "videoDescriptions": [

        ],
        "relatedSeriesIds": [
            5,
            20,
            9
        ],
    },
}

export default posts;