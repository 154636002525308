import React from "react";

import img1 from "../../Utils/images/4Image/1.png"
import img2 from "../../Utils/images/4Image/2.png"
import img3 from "../../Utils/images/4Image/3.jpeg"
import img4 from "../../Utils/images/4Image/4.png"
import img5 from "../../Utils/images/4Image/5.png"
import img6 from "../../Utils/images/4Image/6.png"
import style from "../ImageAccordeonHomepage/ImageAccordeonHomepage.css"

const SixImage = (props) => {
    return (
    <div className="four-image-div">
        <img className="four-image" src={img1}></img>
        <img className="four-image" src={img2}></img>
        <img className="four-image" src={img3}></img>
        <img className="four-image" src={img4}></img>
        <img className="four-image" src={img5}></img>
        <img className="four-image" src={img6}></img>
    </div>
    )
}

export default SixImage;