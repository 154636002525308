import React from "react";
// import ScheduleBlock from "../Series/Schedule/ScheduleBlock";

const ImageDescription = (props) =>{
    let { description, imageNum, episode, serie} = props;
    return (
        <div>
            <p>
                {description}
            </p>
            <img style={{maxWidth:'100%'}} src={`../../${serie}/screenshots/Ep.${episode}/${imageNum+1}.png`} />
        </div>
    )
}

export default ImageDescription