const series = {
    "Naked Nightmare": {
        "id": 1,
        "title": "Naked Nightmare",
        "link": "series/naked-nightmare",
        "description":    "This are instructional videos, in which I am in front of an audience, and one by one I lose my clothes without noticing. I get humiliated by the announcers.",
        "shortDesc":  "Instructional videos, in front of an audience, and my clothes start disappearing",
        "summary":    "Our worst nightmares become my reality. As I go to a TV show, of instructional or walk-throughs videos, I start losing my clothes, one by one. without noticing. The two announcers, Sally and Kendra, start making fun of me, and the audience is quick to join in with the laughs. From a quick workout excercise, to a yoga lesson, to some magic tricks, I do my best to teach you, but unfortunately fall 'short'. Relieve the past episodes, and let me know what the new ones should be",
        "imgSrc": "Naked Nightmare/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Mean Celebrity": {
        "id": 2,
        "title": "Mean Celebrity",
        "link": "series/mean-celebrity",
        "description":    "Long Description",
        "shortDesc":  "Nohomo71826, a successful actor, is mean, but for every insults he loses a piece of clothing",
        "summary":  "An actual series, in which each episode is related to the previous ones. I recommend to start watching from episode 1. Follow the adventures of Nohomo71826, a successful actor, that also happens to be an assh... a little mean. However for each insult he loses a piece of clothing. From bad interviews, to going to jail, if you are looking for adventure, this series has it all.",
        "imgSrc": "Mean Celebrity/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Lonely Patient": {
        "id": 3,
        "title": "Lonely Patient",
        "link": "series/lonely-patient",
        "description": "Long Description",
        "shortDesc":  "A sick man, has to confront the reality of his disease, and loneliness, making ends meet through questionable means",
        "summary":  "An actual series, in which each episode is related to the previous ones. I recommend to start watching from episode 1. Follow the adventures of Nohomo71826, a lonely man that goes to the hospital to deal with a headache, but he realizes he has a rare disease. Over the course of the season he tries to deal with it, alone and with no money, but with the help of a channel he created.",
        "imgSrc": "Lonely Patient/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Race Against Time": {
        "id": 4,
        "title": "Race Against Time",
        "link": "series/race-against-time",
        "description":    "Long Description",
        "shortDesc":  "After angering a witch, I am cursed and have to complete tasks before a certain time before I lose my clothes.",
        "summary":    "An actual series, in which each episode is related to the previous ones. I recommend to start watching from episode 1. Follow the adventures of Nohomo71826, as he makes a witch mad after he completes a dare, and is forced to deal with the fact that everytime somebody gives him an order or task he has to complete it as fast as possible, or he will start losing his clothes",
        "imgSrc": "Race Against Time/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Stolen Clothes": {
        "id": 5,
        "title": "Stolen Clothes",
        "link": "series/stolen-clothes",
        "description": "Long Description",
        "shortDesc":  "I get my clothes stolen, wether it is at my house at the gym, at the office, etc. and have to look for them naked",
        "summary":  "Nobody likes a bully, but that is exactly what I face in the series. My clothes get stolen, wether it is by a friend at my house, a coworker at the office or elsewhere. I now have to go get them, completely naked for everyone to see. If forced nudity, and complete embarrassment is what you are looking for, then this is the best place to look for it.",
        "imgSrc": "Stolen Clothes/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Naked in Park": {
        "id": 6,
        "title": "Naked in Park",
        "link": "series/naked-in-park",
        "description":    "I go to a park and take off my clothes. I play in the games, and even masturbate. Interested? Come see all 7 videos I have so far",
        "shortDesc":  "I go to a park and take off my clothes. I play in the games, and even masturbate.",
        "summary":  "This is a series of videos of me going to the park naked. Currently, it consists of 7 videos. The first 3 parts are during the night, while in the next 4 I take some more risk and go during the day. I do a little bit of everything, from playing in the games, to running around, and to masturbating, and in some of them I even get caught while trying to run and hide. This are the riskiest video I have ever made, and outside of by my neighbors the only ones where I have been caught,  and so I have decided to dedicate a page to them. The adrenaline was so high, and I really enjoyed them, nonetheless, this are probably the only ones I will make.",
        "imgSrc": "Naked in Park/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Open Naked": {
        "id": 7,
        "title": "Open Naked",
        "link": "series/open-naked",
        "description":    "Long Description",
        "shortDesc":  "I open the door naked, wether it is knock and run, amazon, or the pizza man",
        "summary":  "There is a lot of people that like knocking on doors, so let's give them a surprise when I open it. This series consist of opening the door for multiple reasons, wether it is an amazon package or a game of knock and run game, wether I am caught at an unfortunate time, or a lost bet, I will have no secrets at the end.",
        "imgSrc": "Open Naked/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Peeing": {
        "id": 8,
        "title": "Peeing",
        "link": "series/peeing",
        "description":    "Long Description",
        "shortDesc":  "I have to pee, and I do it outdoors, or indoors, and I even drink it.",
        "summary":  "A wide variety of videos of me peeing. From inside the bathroom to outside my house, from wasting it to even drinking it. This is always a 'hard' video to make because I cannot pee when I'm fully erect, so I always had to relax a lot, and wait for me to calm down. I hope you enjoy these videos.",
        "imgSrc": "Peeing/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Pantsed": {
        "id": 9,
        "title": "Pantsed",
        "link": "series/pantsed",
        "description":    "Long Description",
        "shortDesc": "Short Description",
        "summary":  "One of the most embarrassing things in the planet is getting pantsed. Worse, when it is in front of your boss. Even worse, in front of your crush's family. This series, deals with me getting pantsed in different situations. If you like seeing people get embarrassed, or think its funny, this series will give you the dose that you need.",
        "imgSrc": "Pantsed/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Wedgie": {
        "id": 10,
        "title": "Wedgie",
        "link": "series/wedgie",
        "description":    "Long Description",
        "shortDesc":  "I give myself a wedgie, usually until I break it wether is at home or in public",
        "summary": "In this series, I get a wedgie, and suffer through the pain. Wether I am in my house, just outside of it, in a meeting or anywhere, I continue until I break it. After the pain, comes the realization that I no longer have my underwear with me.",
        "imgSrc": "Wedgie/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Spanking": {
        "id": 11,
        "title": "Spanking",
        "link": "series/spanking",
        "description":    "Long Description",
        "shortDesc":  "I spank myself with different objects, in different places, for different reasons.",
        "summary":  "In this series, I spank myself with different objects, from my hand, a wet towel, a resistance band and more. I can get spanked anywhere and with anything, as a lost bet, or as punishment for my wrongful actions. Anything goes, as long as it leaves my butt red",
        "imgSrc": "Spanking/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Naked Outdoors": {
        "id": 12,
        "title": "Naked Outdoors",
        "link": "series/naked-outdoors",
        "description":    "I go naked, usually to a yard, or outdoors, like a street. They are fun videos, usually innocent fun. Sound like something interesting to you?",
        "shortDesc":  "I go naked, usually to a yard, or outdoors, like a street. They are fun, innocent videos",
        "summary":  "In this series, I go outside, and get naked. From there what happens is unpredictable. Wether it is running on the street, climbing a tree, or trying to reach the basket, the only thing certain is that I'm naked outdoors. Enjoy",
        "imgSrc": "Naked Outdoors/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Dancing": {
        "id": 13,
        "title": "Dancing",
        "link": "series/dancing",
        "description":    "Long Description",
        "shortDesc":  "All types of dancing, twerking, strip teases, outdoors, inside the house.",
        "summary":  "Who doesn't love dancing, wether I am home alone, doing strip teases, dancing in my yard, losing bets or anything, dancing is great! What makes dancing better? Well being naked of course, come see how I dance, and join me if you wish",
        "imgSrc": "Dancing/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Ice in Underwear": {
        "id": 14,
        "title": "Ice in Underwear",
        "link": "series/ice-in-underwear",
        "description":    "Long Description",
        "shortDesc":  "Really, more like putting ice in my butt and penis, and see how long I can last.",
        "summary":  "In this series, I put ice in my private parts, my butt and my penis, and see how long I can last. Wether I'm wearing underwear, or just something to hold the ice in place, wether I am inside or outside, I see how long I can deal with the pain",
        "imgSrc": "Ice in Underwear/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Food": {
        "id": 15,
        "title": "Food",
        "link": "series/food",
        "description":    "Long Description",
        "shortDesc":  "Anything related to what I do to my food. Eating from body, the floor, etc. ",
        "summary":  "In this series, I do different things related to food, and eating it. Wether it is putting it in my body, a sausage inside my butthole, or eating like a dog, if you want to feel disgusted (or turned on) by my eating, this is the series for you",
        "imgSrc": "Food/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Pooping": {
        "id": 16,
        "title": "Pooping",
        "link": "series/pooping",
        "description":    "Long Description",
        "shortDesc":  "This series deals with poop. Eating it, licking it, spreading it on me. You name it, it is here",
        "summary":  "A series of videos of me pooping. Do not watch this one, if you are easy to puke. Wether it is grabbing it and eating it, placing it on the floor and licking it, eating directly from the toilet, or spreading it all over me, this series deals with poop and poop only. Are you into that? Is that a turn on for you? Then watch the following episodes.",
        "imgSrc": "Pooping/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Extreme Dares": {
        "id": 17,
        "title": "Extreme Dares",
        "link": "series/extreme-dares",
        "description":    "Long Description",
        "shortDesc":  "These are painful dares, wether it is putting toothpaste or orgasm torture, this is the place to go",
        "summary":  "The most painful dares deserve their own series. While I do not actively look to do this dares (for obvious reasons), I do just about everything that I am dared to do. Wethere it is putting toothpaste on my penis, an orgasm torture dare, or even something you just thought about, if you want to see me in pain, this is the place.",
        "imgSrc": "Extreme Dares/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Jumping": {
        "id": 18,
        "title": "Jumping",
        "link": "series/jumping",
        "description":    "Long Description",
        "shortDesc":  "As the title says, videos of me jumping. Some great shots of my penis and butt",
        "summary":  "The premise of this series of videos is pretty simple. When I jump, I get some great shots of my butt, and my bouncing penis. It is pretty innocent fun, with some great nudity. If you are not looking for anything too complicated, this is one of the best series here.",
        "imgSrc": "Jumping/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Masturbating": {
        "id": 19,
        "title": "Masturbating",
        "link": "series/masturbating",
        "description":    "Long Description",
        "shortDesc":  "I masturbate in different circumstances, and I p[lay with my body",
        "summary":  "The name is pretty self explanatory. Masturbating is one of the most rewarding things to do, and I assume that is why a lot of you are here. However, in this series, I don't just masturbate. There are different ways of feeling myself, and yes, masturbating. I try to keep it fun and innocent enough, and I have even masturbated to my own videos. Sometimes, I masturbate watching somebody else. See all the different ways here.",
        "imgSrc": "Masturbating/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Shower": {
        "id": 20,
        "title": "Shower",
        "link": "series/shower",
        "description":    "Long Description",
        "shortDesc":  "I take a shower in different positions. If you like wet bodies and me touching myself here is the place",
        "summary": "As the title says, the videos consist of me taking a shower. Of course, I don't just take a shower. I do my best to add some variety to it, wether that is by doing different poses, or even acting like a dog. If you enjoy wet, naked bodies, then this series is the one for you.",
        "imgSrc": "Shower/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Waking Up": {
        "id": 21,
        "title": "Waking Up",
        "link": "series/waking-up",
        "description":    "Long Description",
        "shortDesc":  "Some fun videos, where I wake up either already naked or get naked. For the not hardcore fans",
        "summary": "Have you ever gone to sleep naked? Well, if you have, then you know that the best part about it is waking up. Whether I wake up with a boner, or just soft, it is a very simple video, of how I start my day. Sometimes, I will stretch, other times, put my clothes on, etc. A very softcore series for those not looking for the other stuff.",
        "imgSrc": "Waking Up/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Workout": {
        "id": 22,
        "title": "Workout",
        "link": "series/workout",
        "description":    "Long Description",
        "shortDesc":  "As I work out and do different excercises there are a lot of different views of my sweaty body",
        "summary": "Well, I love to workout. During a workout however, there is no stone left unturned. Through different poses, you will see every part of me. Also, my workouts can be pretty intensive, for me at least, so I will be all covered up by my sweat. While in this series, I simply workout naked, but I don't acknowledge it, so if you are looking for some fun videos, this is the series for you.",
        "imgSrc": "Workout/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
    "Webcam": {
        "id": 23,
        "title": "Webcam",
        "link": "series/webcam",
        "description":    "Long Description",
        "shortDesc":  "I record myself, through a webcam, doing ordinary things. Watching a game, in the bathroom, etc.",
        "summary": "One of the most well known services is the webcam. In this series, I record myself through a webcam, doing ordinary things. Whether it is supporting my favorite sports team, needing to go to the bathroom, playing video games, etc. I allow you to see everything through the webcam lense.",
        "imgSrc": "Webcam/main.png",
        "nextEpisode": "May 18, 2023",
        "futureEpisodes": [
            "other",
        ],
    },
}

export default series;