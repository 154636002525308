let questions = {
    "HOMEPAGE": {
        '1': {
            question: "Can I see your face?",
            answer: "Unfortunately, no. Email me at nooo3noo2no1@gmail.com or contact me through twitter @nohomo71826 and I will give you some exclusive images, to make up for it though. Sorry",
            imgSrc: "c"
        },
        '2': {
            question: "What is your favorite post?",
            answer: "It has to be my first time outside. I was so nervous: https://nooo3noo2no1.wixsite.com/nohomo71826/post/my-first-time-outside-naked-in-public",
            imgSrc: "c"
        },
        '3': {
            question: "Do you still get embarrassed?",
            answer: "Yes, especially when I go in public. I'm always scared how people are going to react if they see me. Even for the indoors ones, to post them I usually have to watch it before to get turned on enough and then close them, otherwise I wouldn't dare. However I love the positive responses I usually receive",
            imgSrc: "c"
        }
    }
}

export default questions;