import React, { useEffect, useState } from "react";

import ImageLink from "./Utils/ImageLink";
import Block from "./Utils/Block";
import style from "./ScheduleBlock.css"
import { getEpisodeFromId } from "../../Utils/utils";

const ScheduleBlock = (props) => {
    const {
        episodesIds,
        release,
    } = props;
    const [seriesId1, setSeriesId1] = useState();
    const [seriesId2, setSeriesId2] = useState();
    const [seriesId3, setSeriesId3] = useState();
    const [episode1, setEpisode1] = useState();
    const [episode2, setEpisode2] = useState();
    const [episode3, setEpisode3] = useState();
    const fetchData = () => {
        for (let i = 0; i < 3; i++) {
            getEpisodeFromId(episodesIds[i]).then((epi) => {
                if (i === 0) {
                    setSeriesId1(epi.seriesId);
                    setEpisode1(epi);
                }else if (i === 1) {
                    setSeriesId2(epi.seriesId);
                    setEpisode2(epi);
                } else if (i === 2) {
                    setSeriesId3(epi.seriesId);
                    setEpisode3(epi);
                }
            });
        }
    };
    useEffect(()=> fetchData(), []);
    return (
    <div style={{backgroundColor:'rgb(212,212,212)'}}>
        <div>
            <h1 className="center-header" style={{paddingTop:'0px'}}>
                Release Schedule
            </h1>
        </div>
        <div className="repeater">
            <div className = "post-coming">
                <Block episode={episode1} seriesId={seriesId1} alt="whocares" release={release}></Block>
            </div>
            <div className = "post-coming">
                <Block episode={episode2} seriesId={seriesId2} alt="whocares" release={release}></Block>
            </div>
            <div className = "post-coming">
                <Block episode={episode3} seriesId={seriesId3} alt="whocares" release={release}></Block>
            </div>
        </div>
    </div>
    )
}

export default ScheduleBlock;