import React from "react";
import BlogBlock from "./BlogBlock";
import style from "../../../Serie/EpisodesBlock/Utils/EpisodeBlock.css";
//import EpsiodeBlock from "./Utils/EpsiodeBlock";

const BlogRow = (props) =>{
    const {
        episodes
    } = props;
    if (episodes !== undefined) {
        return (
            <div style={{backgroundColor:'rgb(212,212,212)'}} className = "repeater">
                {true && episodes.map((episode) => {
                    return <BlogBlock episode = {episode}></BlogBlock>
                })}
            </div>
    )}
}

export default BlogRow;