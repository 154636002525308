import React, { useEffect, useState } from "react";

import style from "../ImageAccordeonHomepage/ImageAccordeonHomepage.css"
import { getEpisodeFromId } from "../../Utils/utils";
import LatestPost from "./LatestPost/LatestPost";

const LatestPosts = (props) => {
    let lastId=51;
    //const [seriesId1, setSeriesId1] = useState();
    //const [seriesId2, setSeriesId2] = useState();
    //const [seriesId3, setSeriesId3] = useState();
    const [isLoading, setLoading] = useState(true);
    const [episode1, setEpisode1] = useState();
    const [episode2, setEpisode2] = useState();
    const [episode3, setEpisode3] = useState();
    const fetchData = () => {
        for (let i = 0; i < 3; i++) {
            getEpisodeFromId(lastId-i).then((epi) => {
                if (i === 0) {
                    setEpisode1(epi);
                }else if (i === 1) {
                    setEpisode2(epi);
                } else if (i === 2) {
                    setEpisode3(epi);
                    setLoading(false);
                }
            });
        }
    };
    useEffect(()=> fetchData(), []);
    if (isLoading) {
        return (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}>Loading</div>
      );
    }
    return (
        <div className="latest-posts">
            <LatestPost episode={episode1} />
            <LatestPost episode={episode2} />
            <LatestPost episode={episode3} />
        </div>
    )
}

export default LatestPosts;