import React from "react";
import style from "./SubscribeFormShort.css"
import LINKS from "../../Utils/links";
import { setForm } from "../../Utils/utils";

const SubscribeFormShort = (props) =>{
    async function myFunction() {
        let email = document.getElementsByName("email")[0].value;
        const body = {
            email: email,
        };
        setForm(body, LINKS.FORMS.subscribeForm);
        let form = document.getElementById("sideForm");
        if (document.getElementById("thank-you-side") === null) {
            form.innerHTML += `<p id="thank-you-side" style=text-align:center>Thank you for submitting!</p>`;
        }
        return true;
    }

    return (
        <div className="side-form">
            <form action="javascript:;" id = "sideForm">
                <h1 className="form-element">
                    Weekly Video and pictures (Don't want to spam the blog)
                </h1>
                <div className="side-form-fields">
                    Email: *
                    <br /><br />
                    <input type="text" name="email" className="side-form-text" required/> 
                    <br /><br />
                    <button className="side-form-submit" type="reset" value="reset" onClick={myFunction}>
                        Subscribe
                    </button>
                </div>
            </form>
        </div>
    );
}

export default SubscribeFormShort;