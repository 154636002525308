import React, { useEffect, useState } from "react";
import { flushSync } from 'react-dom';
import { useParams } from "react-router-dom";
import Slider from "./Slider/Slider";

const VideoChannel = (props) =>{
    const {
        channel
    } = props;
    return (
        <div style={{backgroundColor:'rgb(212,212,212)'}}>
            <Slider channel={channel}></Slider>
        </div>
    )
}

export default VideoChannel;