import React from "react";

import style from "./FormFooter.css"
import LINKS from "../../Utils/links";
import { setForm } from "../../Utils/utils";

const FormFooter = () =>{
    async function saveFormFooter() {
        let suggestion = document.getElementsByName("suggestion-footer")[0].value;
        let email = document.getElementsByName("email-footer")[0].value;
        if (email === undefined) {
            email = null;
        }
        const body = {
            suggestion: suggestion,
            email: email,
        };
        setForm(body, LINKS.FORMS.formFooter);
        let form = document.getElementById("footerForm");
        if (document.getElementById("thank-you-footer") === null) {
            form.innerHTML += `<p id="thank-you-footer" style=text-align:center>Thank you for submitting!</p>`;
        }
        return true;
    }

    return (
        <div style={{backgroundColor:'rgb(206,214,181)'}}>
            <h1 className="header-footer">
                Have Any Requests For My Next Video?
            </h1>
            <div className="footer-side-form">
                <form action="javascript:;" id = "footerForm">
                    Tell Me Here: *
                    <br /><br />
                    <input type="text" name="suggestion-footer" className="footer-form-text" required/> 
                    <br /><br />
                    Email: (optional, in case it is not clear)
                    <br /><br />
                    <input type="text" name="email-footer" className="footer-form-text" required/> 
                    <br /><br />
                    <button className="footer-form-submit" type="reset" value="reset" onClick={saveFormFooter}>
                        Subscribe
                    </button>
                </form>
            </div>
        </div>
    )
}

export default FormFooter;