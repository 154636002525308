import video1 from './Dancing/Ep.1/video.mp4'
import video2 from './Naked Outdoors/Ep.1/video.mp4'
import video3_1 from './Peeing/Ep.1/video1.mp4'
import video3_2 from './Peeing/Ep.1/video2.mp4'
import video3_3 from './Peeing/Ep.1/video3.mp4'
import video3_4 from './Peeing/Ep.1/video4.mp4'
import video5 from './Peeing/Ep.2/video.mp4'
import video7 from './Waking Up/Ep.2/video.mp4'
import video8 from './Masturbating/Ep.1/video.mp4'
import video9 from './Jumping/Ep.1/video.mp4'
import video10 from './Naked Outdoors/Ep.3/video.mp4'
import video11 from './Ice in Underwear/Ep.1/video.mp4'
import video13 from './Masturbating/Ep.2/video.mp4'
import video14 from './Naked Outdoors/Ep.4/video.mp4'
import video15 from './Shower/Ep.2/video.mp4'
import video16 from './Naked in Park/Ep.2/video.mp4'
import video17 from './Extreme Dares/Ep.1/video.mp4'
import video18 from './Dancing/Ep.2/video.mp4'
import video19 from './Ice in Underwear/Ep.2/video.mp4'
import video20 from './Ice in Underwear/Ep.3/video.mp4'
import video21 from './Naked in Park/Ep.4/video.mp4'
import video22 from './Spanking/Ep.1/video.mp4'
import video23 from './Wedgie/Ep.1/video.mp4'
import video24 from './Naked Nightmare/Ep.1/video.mp4'
import video25 from './Naked in Park/Ep.5/video.mp4'
import video26 from './Open Naked/Ep.1/video.mp4'
import video27 from './Dancing/Ep.3/video.mp4'
import video28 from './Naked in Park/Ep.7/video.mp4'
import video29 from './Extreme Dares/Ep.2/video.mp4'
import video30 from './Naked in Park/Ep.1/video.mp4'
import video31_1 from './Peeing/Ep.3/video1.mp4'
import video31_2 from './Peeing/Ep.3/video2.mp4'
import video32 from './Naked Nightmare/Ep.2/video.mp4'
import video33 from './Stolen Clothes/Ep.1/video.mp4'
import video34 from './Naked in Park/Ep.3/video.mp4'
import video35 from './Pantsed/Ep.1/video.mp4'
import video36 from './Peeing/Ep.4/video.mp4'
import video37 from './Wedgie/Ep.2/video.mp4'
import video38 from './Naked Nightmare/Ep.3/video.mp4'
import video39_1 from './Dancing/Ep.4/video1.mp4'
import video39_2 from './Dancing/Ep.4/video2.mp4'
import video39_3 from './Dancing/Ep.4/video3.mp4'
import video40 from './Naked in Park/Ep.6/video.mp4'
import video41_1 from './Spanking/Ep.2/video1.mp4'
import video41_2 from './Spanking/Ep.2/video2.mp4'
import video42 from './Wedgie/Ep.3/video.mp4'
import video43 from './Open Naked/Ep.2/video.mp4'
import video44 from './Mean Celebrity/Ep.1/video.mp4'
import video45 from './Food/Ep.1/video.mp4'
import video46 from './Lonely Patient/Ep.1/video.mp4'
import video47 from './Race Against Time/Ep.1/video.mp4'
import video48_1 from './Pooping/Ep.1/video1.mp4'
import video48_2 from './Pooping/Ep.1/video2.mp4'
import video49 from './Naked Nightmare/Ep.4/video.mp4'
import video50 from './Mean Celebrity/Ep.2/video.mp4'
import video51 from './Lonely Patient/Ep.2/video.mp4'
import video52 from './Stolen Clothes/Ep.2/video.mp4'
import video53 from './Race Against Time/Ep.2/video.mp4'
import video54 from './Webcam/Ep.1/video.mp4'

const videoSeries = {
    "Dancing Outside (From afar)": {
        id: 1,
        video: video1,
    },
    "My First Time Outside (Naked in Public)": {
        id: 2,
        video: video2,
    },
    "My first Xvideos request (the making)": {
        id: 3,
        video: [
            video3_1,
            video3_2,
            video3_3,
            video3_4,
        ]
    },
    "Cold Outside (First Picture Story)": {
        id: 4,
        video: null,
    },
    "Peeing in a 1.5L bottle (and drinking it)": {
        id: 5,
        video: video5,
    },
    "Perfectly Soft (Picture session)": {
        id: 6,
        video: null,
    },
    "Waking up and getting naked": {
        id: 7,
        video: video7,
    },
    "First Recording Masturbating": {
        id: 8,
        video: video8,
    },
    "Jumping Inside Dare": {
        id: 9,
        video: video9,
    },
    "Went naked Outside again": {
        id: 10,
        video: video10,
    },
    "Ice in underwear (butt version)": {
        id: 11,
        video: video11,
    },
    "Naked Workout (Picture Session)": {
        id: 12,
        video: null
    },
    "Feeling myself": {
        id: 13,
        video: video13,
    },
    "Run naked in street dare": {
        id: 14,
        video: video14,
    },
    "Shower part 2": {
        id: 15,
        video: video15,
    },
    "Naked in the park Part 2": {
        id: 16,
        video: video16,
    },
    "Toothpaste on penis dare": {
        id: 17,
        video: video17,
    },
    "Untold Story of Dare Dancing Outside": {
        id: 18,
        video: video18,
    },
    "Ice in underwear Butt Second Time": {
        id: 19,
        video: video19,
    },
    "Ice in underwear Penis": {
        id: 20,
        video: video20,
    },
    "Naked in Park (Part 4)": {
        id: 21,
        video: video21,
    },
    "Spanking": {
        id: 22,
        video: video22,
    },
    "Wedgie": {
        id: 23,
        video: video23,
    },
    "Naked Nightmare Challenge (First website Request)": {
        id: 24,
        video: video24,
    },
    "Naked in Park (Part 5)": {
        id: 25,
        video: video25,
    },
    "Lost towel Locked outside": {
        id: 26,
        video: video26,
    },
    "Full Monty Strip Dance": {
        id: 27,
        video: video27,
    },
    "Naked in the park (part 7)": {
        id: 28,
        video: video28,
    },
    "Shower Orgasm Torture Dare": {
        id: 29,
        video: video29,
    },
    "Naked in the park (part 1)": {
        id: 30,
        video: video30,
    },
    "Peeing Outside (and bonus inside)": {
        id: 31,
        video: [
            video31_1,
            video31_2,
        ],
    },
    "Naked Nightmare (Christmas Magic Edition)": {
        id: 32,
        video: video32,
    },
    "Stolen Clothes/Towel and Locked Outside": {
        id: 33,
        video: video33,
    },
    "Naked in the park (part 3)": {
        id: 34,
        video: video34,
    },
    "Pantsed in front of boss": {
        id: 35,
        video: video35,
    },
    "Peeing and drinking it": {
        id: 36,
        video: video36,
    },
    "Wedgie and Locked Outside": {
        id: 37,
        video: video37,
    },
    "Naked Nightmare (Yoga Edition)": {
        id: 38,
        video: video38,
    },
    "Twerking (First Try)": {
        id: 39,
        video: [
            video39_1,
            video39_2,
            video39_3,
        ],
    },
    "Naked in the Park part 6": {
        id: 40,
        video: video40,
    },
    "Spanking with Yellow Band": {
        id: 41,
        video: [
            video41_1,
            video41_2,
        ],
    },
    "Broken Wedgie And Feeling Myself": {
        id: 42,
        video: video42,
    },
    "Receiving Amazon Package Naked": {
        id: 43,
        video: video43,
    },
    "The Interview": {
        id: 44,
        video: video44,
    },
    "Eating from Body": {
        id: 45,
        video: video45,
    },
    "Feeling Bad": {
        id: 46,
        video: video46,
    },
    "The Witch": {
        id: 47,
        video: video47,
    },
    "Eating Poop From My Hand": {
        id: 48,
        video: [
            video48_1,
            video48_2,
        ]
    },
    "Naked Nightmare Concert": {
        id: 49,
        video: video49,
    },
    "Breaking News": {
        id: 50,
        video: video50,
    },
    "The Realization": {
        id: 51,
        video: video51,
    },
    "Stolen Clothes in Office": {
        id: 52,
        video: video52,
    },
    "The Boss": {
        id: 53,
        video: video53,
    },
    "Watching Sports Game": {
        id: 54,
        video: video54,
    },
}

export default videoSeries;