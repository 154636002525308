import React from "react";

import style from "./Popular.css"
import TextBlock from "./Utils/TextBlock";

const Popular = (props) => {
    return (
    <div style={{backgroundColor:'white', paddingBottom:'2%'}}>
        <div>
            <h1 className="center-header">
                My Most Popular Series
            </h1>
        </div>
        <div className="repeater">
            <div className = "popular-block">
                <TextBlock seriesId={1}></TextBlock>
            </div>
            <div className = "popular-block">
                <TextBlock seriesId={6} alt="whocares"></TextBlock>
            </div>
            <div className = "popular-block">
                <TextBlock seriesId={12} alt="whocares"></TextBlock>
            </div>
        </div>
    </div>
    )
}

export default Popular;