import React from "react";

import img1 from "../../Utils/images/ImageAccordeon/IA1.jpeg"
import img2 from "../../Utils/images/ImageAccordeon/IA2.png"
import img3 from "../../Utils/images/ImageAccordeon/IA3.jpg"
import img4 from "../../Utils/images/ImageAccordeon/IA4.png"
import img5 from "../../Utils/images/ImageAccordeon/IA5.png"
import img6 from "../../Utils/images/ImageAccordeon/IA6.png"
import style from "./ImageAccordeon.css"

const ImageAccordeon = (props) => {
    return (
    <div className="image-accordeon">
        <img className="accordeon-image" src={img1}></img>
        <img className="accordeon-image" src={img2} style={{width:'10%'}}></img>
        <img className="accordeon-image" src={img3}></img>
        <img className="accordeon-image" src={img4}></img>
        <img className="accordeon-image" src={img5}></img>
        <img className="accordeon-image" src={img6}></img>
    </div>
    )
}

export default ImageAccordeon;