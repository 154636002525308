import React from "react";

import style from "../ImageAccordeonHomepage/ImageAccordeonHomepage.css"
import FAQ from "./FAQ/FAQ";

const FAQS = (props) => {
    return (
    <div className="faqs">
        <h1 style={{textAlign:'center'}}>Frequently Asked Questions:</h1>
        <FAQ />
    </div>
    )
}

export default FAQS;