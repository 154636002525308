import React from "react";
import style from "./EpisodeRepeater.css"
import EpisodeBlock from "./EpisodeBlock";

const EpisodeRepeater = (props) => {
    const { episodes } = props;
    return (
        <div className="repeater">
            {episodes.map(episode => { 
            return (
                <div className = "repeater-episode">
                    <EpisodeBlock episode={episode}/>
                </div>
            )})}
        </div>
    )
}

export default EpisodeRepeater;