import React from "react";

import style from "./BlogCategories.css"
//import headerImg from "../../Utils/images/headerImage.png"

const BlogCategories = (props) => {
    const { category } = props;
    return (
        <div className="topnavBlog" id="myTopnav">
            <div style={{display:'inline-block', marginRight:'20px'}}>
                <a href="/myblog/all" className={category==="all" ? "active" : ""}>All</a>
                <a href="/myblog/videos" className={category==="videos" ? "active" : ""}>Videos</a>
                <a href="/myblog/images" className={category==="images" ? "active" : ""}>Images</a>
                <a href="/myblog/exclusive" className={category==="exclusive" ? "active" : ""}>Webpage Exclusive</a>
                <a href="/myblog/dares" className={category==="dares" ? "active" : ""}>Dares</a>
                <a href="/myblog/public" className={category==="public" ? "active" : ""}>Naked Outside</a>
            </div>
        </div>
    )
}

export default BlogCategories;