import React from "react";
import RelatedSerieBlock from "./Utils/RelatedSerieBlock";
// import ScheduleBlock from "../Series/Schedule/ScheduleBlock";

const RelatedSeries = (props) =>{
    let { seriesIds } = props;
    return (
        <div>
            <h1>
                Related Series
            </h1>
            <div style={{display:'inline-flex'}}>
                {seriesIds.map((seriesId) => {
                    return <RelatedSerieBlock serieId={seriesId} />
                })}
            </div>
        </div>
    )
}

export default RelatedSeries