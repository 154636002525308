import React from "react";
import style from "./SerieFeedback.css"
import LINKS from "../../Utils/links";
import { setForm } from "../../Utils/utils";

const SerieFeedback = (props) =>{
    let {
        series,
        episodes,
    } = props

    async function submitFeedback() {
        let favorite = document.getElementById("favorite-ep").value;
        let future = document.getElementById("future-ep").value;
        let rating = document.querySelector('input[name="rate"]:checked').value;
        let suggestion = document.getElementsByName("suggestion-serie")[0].value;
        const body = {
            seriesId: series.id,
            favorite: favorite,
            future: future,
            rating: rating,
            suggestion: suggestion,
        };
        setForm(body, LINKS.FORMS.seriesFeedback);
        let form = document.getElementById("seriesFeedback");
        if (document.getElementById("thank-you-feedback") === null) {
            form.innerHTML += `<p id="thank-you-side" style=text-align:center>Thank you for your feedback!</p>`;
        }
        return true;
    }

    return (
        <div>
            <h1 style={{textAlign:'center'}}>
                NEXT EPISODE ON {series.nextEpisode}!!
            </h1>
            <div className="serie-form">
                <form action="javascript:;" id = "seriesFeedback">
                    <h1 className="form-element">
                        {series.title} Feedback
                    </h1>
                    <p>
                        If you can, help me improve the series by filling out the form below.
                    </p>
                    <p>Favorite Episode: *</p>
                    <select className="same-line-separation" id="favorite-ep" placeholder="Choose an option">
                        {episodes.map((episode, index) => {
                            let value = `Ep.${index+1}`;
                            return <option value={value}>{value}</option>
                        })}
                    </select> 
                    <div className="same-line-feedback">
                        <p className="same-line-separation">Future Episodes: *</p>
                        <p className="same-line-separation">Suggestion:</p>
                    </div>
                    <div className="same-line-feedback">
                        <select className="same-line-separation" id="future-ep" placeholder="Choose an option">
                            {series.futureEpisodes.map((value) => {
                                return <option value={value}>{value}</option>
                            })}
                        </select> 
                        <input style={{width:'40%', paddingRight:'10%'}} type="text" name="suggestion-serie" className="serie-form-text" /> 
                    </div>
                    <div class="rate">
                        <p style={{marginBottom:'0%'}}>Rate the Series *</p>
                        <input type="radio" id="star5" name="rate" value="5" />
                        <label for="star5" title="text">5 stars</label>
                        <input type="radio" id="star4" name="rate" value="4" />
                        <label for="star4" title="text">4 stars</label>
                        <input type="radio" id="star3" name="rate" value="3" />
                        <label for="star3" title="text">3 stars</label>
                        <input type="radio" id="star2" name="rate" value="2" />
                        <label for="star2" title="text">2 stars</label>
                        <input type="radio" id="star1" name="rate" value="1" />
                        <label for="star1" title="text">1 star</label>
                        <br /><br />
                        <div class="text"></div>
                    </div>
                    <button className="series-feedback-submit" type="reset" value="reset" onClick={submitFeedback}>
                        Review
                    </button>
                </form>
            </div>
        </div>
    );
}

export default SerieFeedback;