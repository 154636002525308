import React from "react";

import style from "../../ImageAccordeonHomepage/ImageAccordeonHomepage.css"
import Accordion from "./Accordion";

import questions from "../../../Utils/FAQS/questions";

const FAQ = (props) => {
    return (
    <div className="faq">
        <Accordion question={questions["HOMEPAGE"]['1'].question} answer={questions["HOMEPAGE"]['1'].answer}/>
        <Accordion question={questions["HOMEPAGE"]['2'].question} answer={questions["HOMEPAGE"]['2'].answer}/>
        <Accordion question={questions["HOMEPAGE"]['3'].question} answer={questions["HOMEPAGE"]['3'].answer}/>
    </div>
    )
}

export default FAQ;