import React from "react";

import "../../Styles.css";
import channelImports from "../../../../Utils/videoChannels/channelImports";

/*<video src={require("./../../../../Utils/videoChannels/channelImports/smallAfterShower.mp4")/*channelImports.ROAST_ME[0].src/} alt={alt} className="slide-image" />*/
export default function SlideVideo({ src, alt }) {
  return (
      <video className="slide-video-channel" controls controlsList="nodownload" alt={alt}>
          <source src={`${src}`} type="video/mp4"/>
      </video>
  )
}