import React from "react";
// import ScheduleBlock from "../Series/Schedule/ScheduleBlock";
import posts from "../../Utils/Lists/PostList";
import ImageDescription from "./ImageDescription";
import videoSeries from "../../Utils/series/VideoImports";
import RelatedSeries from "../RelatedSeries/RelatedSeries";

const PostContent = (props) =>{
    let { serie, episode, actual } = props;
    let post = posts[episode.name];
    return (
        <div>
            <div>
                <p>
                    <a href='./' target="_blank">
                        This is an episode from the series {serie}. You can watch the other episodes here.
                    </a> 
                    {actual && (
                            ` This is an actual series, so I recommend to watch the episodes in order.` 
                    )}
                    &nbsp;As always the video is at the end. {post.intro}
                </p>
                {
                post.imgDescriptions.map(
                    (desc, index) => {
                       return (
                            <ImageDescription 
                                description={desc}
                                imageNum={index}
                                episode={episode.numEpisode} 
                                serie={serie}
                            />
                       )
                })}
                {post.numVideos===1 && (
                    <div>
                        <p>Here is the video:</p>
                        <video style={{maxWidth:'100%'}} controls>
                            <source src={`${videoSeries[episode.name].video}`} type="video/mp4"/>
                        </video>
                    </div>
                )}
                {post.numVideos>1 && (
                    <div>
                        <p>Here are the {post.numVideos} videos:</p>
                        {post.videoDescriptions.map((desc, index) => {
                            return (
                                <div>
                                    <p>{desc}</p>
                                    <video style={{maxWidth:'100%'}} controls>
                                        <source src={`${videoSeries[episode.name].video[index]}`} type="video/mp4"/>
                                    </video>
                                </div>
                            )
                        })}
                    </div>
                )}
                <p>
                    I hope you enjoy. You can check the schedule for my next videos <a href='/series' target="_blank">here</a>.
                </p>
                <p>
                    If you have any suggestions, requests, ideas, or want to be part of the website, please let me know. My email is noo3noo2no1@gmail.com, or my twitter is @nohomo71826all
                </p>
                <p>
                    Thank you for watching
                </p>
            </div>
            <RelatedSeries seriesIds={post.relatedSeriesIds} />
        </div>
    )
}

export default PostContent