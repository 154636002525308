import React, { useContext } from "react";
import { SliderContext } from "../Slider";

import "../Styles.css";

export default function Arrows() {
  const { changeSlide } = useContext(SliderContext);

  return (
    <div className="arrows-channel">
      <div className="arrow-channel left" onClick={() => changeSlide(-1)} />
      <div className="arrow-channel right" onClick={() => changeSlide(1)} />
    </div>
  );
}