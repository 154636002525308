import React from "react";
import Header from "./../Header/Header"
import ImageAccordeonHomepage from "./ImageAccordeonHomepage/ImageAccordeonHomepage";
import Footer from "../Footer/Footer";
import LatestPosts from "./LatestPosts/LatestPosts";
import SideWidgetHome from "./SideWidgetHome/SideWidgetHome";
import FAQS from "./FAQS/FAQS";
import CommentExampleComment from '../Comments/Comments';

const Home = () =>{
    return (
        <div>
            <Header current="home"/>
            <ImageAccordeonHomepage/>
            <div style={{maxWidth:'100%', textAlign:'center'}}>
                <h1 style={{maxWidth:'30%', marginLeft:'35%'}}>
                    Naked Dares And Lost Bets
                </h1>
            </div>
            <div style={{width:'55%', backgroundColor:'rgb(187,217,94)', display:'inline-block', marginLeft:'5%'}}>
               <LatestPosts/>
                <a target="_blank" href={'/series'} style={{color:"black"}}>
                    <button className="all-posts-button">
                        All Posts
                    </button>
                </a>
            </div>
            <div style={{width:'35%', backgroundColor:'rgb(230, 248, 167)', display:'inline-block', marginRight:'5%', verticalAlign:'top'}}>
                <SideWidgetHome />
            </div>
            <FAQS />
            <CommentExampleComment image = 'https://www.gravatar.com/avatar' />
            <Footer />
        </div>
    )
}

export default Home;