import React from "react";
import style from "../SideWidget.css"
import img from "../../Utils/images/AboutMeShort.png"
import Button from "../../Utils/Button/Button";

const AboutShort = (props) =>{
    return (
        <div className="side-element">
            <img src={img} style={{maxWidth:'100%'}} />
            <h3>
                Hi, thanks for reading!
            </h3>
            <p>
                I love the adrenaline from risking getting caught, as well 
                as just enjoy being naked. If you like my videos please 
                feel free to let me know or if you have any requests. I am 
                still a little embarrassed by some of them but will do my 
                best
            </p>
            <Button
                title="Read More"
                type="side-widget"
                link='/about-me'
            />
        </div>
    );
}

export default AboutShort;