import React from "react";
import style from "./EpisodesBlock.css"
import EpisodeRepeater from "./Utils/EpisodeRepeater";

const EpisodesBlock = (props) => {
    const {
        episodes
    } = props;
    let episodeRows = []
    let numEpisodes = episodes.length;
    let leftOvers = numEpisodes%3;
    let row = 0;
    for (let i=0; i<numEpisodes-2; i+=3){
        episodeRows.push([]);
        episodeRows[row].push(episodes[i]);
        episodeRows[row].push(episodes[i+1]);
        episodeRows[row].push(episodes[i+2]);
        row += 1;
    }
    for (let i=0; i < leftOvers; i++) {
        episodeRows.push([]);
        episodeRows[row].push(episodes[numEpisodes-leftOvers+i]);
    }
    return (
        <div>
            <h1 style={{textAlign:'center', paddingTop:'2%'}}>Episodes ({numEpisodes} total)</h1>
            {episodeRows.map(row => {
                return <EpisodeRepeater episodes={row}></EpisodeRepeater>
            })}
        </div>
    );
}

export default EpisodesBlock;