import React from "react";
import style from "./Button.css"

const Button = (props) =>{
    let {
        title,
        type,
        link,
    } = props
    return (
        <a className={`button-${type}`} href={link}>{title}</a>
    );
}

export default Button;