import React, { useEffect, useState } from "react";
import { getEpisodeFromId } from "../../../Utils/utils";
import EpisodeBlock from "../../../Serie/EpisodesBlock/Utils/EpisodeBlock";
import style from "../../../Serie/EpisodesBlock/Utils/EpisodeBlock.css";
//import EpsiodeBlock from "./Utils/EpsiodeBlock";

const BlogBlock = (props) =>{
    const { episode } = props
    return (
        <div className="repeater-episode" style={{marginBottom:"2%"}}>
            <EpisodeBlock episode = {episode}></EpisodeBlock>
        </div>
    )
}

export default BlogBlock;