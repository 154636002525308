import React from "react";

import style from "./SeriesSlider.css"
import SeriesBlock from "./Utils/SeriesBlock";
import SeriesRepeater from "./Utils/SeriesRepeater";

const SeriesSlider = (props) => {
    let ids1 = [1,2,3,4,5,6,7,8,9];
    let ids2 = [10,11,12,13,14,15,16,17,18];
    let ids3 = [19,20,21,22];
    return (
        <div style={{backgroundColor:'rgb(212,212,212)'}}>
            <div>
                <h1 className="center-header">
                    All Series
                </h1>
            </div>
            <SeriesRepeater seriesIds={ids1}/>
            <SeriesRepeater seriesIds={ids2}/>
            <SeriesRepeater seriesIds={ids3}/>
        </div>
    )
}

export default SeriesSlider;