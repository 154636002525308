import React from "react";
import ReactGA from 'react-ga4';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Series from "./Components/Series/Series";
import Serie from "./Components/Serie/Serie";
import Post from "./Components/Post/Post";
import Home from "./Components/Home/Home";
import PostCreate from "./Components/PostCreate/PostCreate";
import About from "./Components/About/About";
import MyBlog from "./Components/MyBlog/MyBlog";
import RoastMe from "./Components/RoastMe/RoastMe";
import LiveStreams from "./Components/LiveStreams/LiveStreams";

const TRACKING_ID = "G-VEFTXL1W9J"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
    return (
        <Router basename="/">
            <Routes>
                <Route exact strict path="/" Component={Home} />
                <Route exact strict path="/about" Component={About} />
                <Route exact strict path="/myblog/:category" Component={MyBlog} />
                <Route
                    path="/myblog"
                    element={ <Navigate to="/myblog/all" /> }
                />
                <Route exact strict path="/series" Component={Series} />
                <Route 
                    exact strict path="/series/:seriesLink" 
                    element={<Serie />}
                />
                <Route 
                    exact strict path="/series/:seriesLink/:seasonEpisode" 
                    element={<Post />}
                />
                <Route exact strict path="/roast-me" Component={RoastMe} />
                <Route exact strict path="/live-streams" Component={LiveStreams} />
                <Route exact strict path="/createPost" Component={PostCreate} />
            </Routes>
        </Router>
    )
}

export default App
