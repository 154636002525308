import React, { useEffect, useState } from "react";
import ImageLink from "./ImageLink"
import { getSeriesFromId } from "./../../../Utils/utils";
import style from "./Block.css"

const Block = (props) => {
    const {
        episode,
        alt,
        seriesId,
        release,
    } = props;
    const [isLoading, setLoading] = useState(true);
    const [serie, setSerie] = useState();
    const fetchData = () => {
        getSeriesFromId(seriesId).then((obj) => {
            setSerie(obj);
            setLoading(false);
    })};
    useEffect(()=> fetchData(), [episode, seriesId]);
    if (isLoading) {
        return (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}>Loading</div>
      );
    }
    let episodeLink = `${serie.link}/${episode.season}_${episode.numEpisode}`
    return (
    <div className="schedule-container">
        {!release && <ImageLink alt={alt} imgSrc={episode.imgSrc} link = {episodeLink}/>}
        {release && <img className="schedule-image" src={episode.imgSrc}/>}
        <div className="schedule-text">
            {!release && (
                <h2 style={{fontSize:"1.6vw", display:"inline"}}>
                    <a target="_blank" href={episodeLink} style={{color:"black"}}>
                        {episode.name}
                    </a>
                </h2>)}
            {release && <h2 style={{fontSize:"1.6vw", display:"inline"}}>{episode.name}</h2>}
            <p style={{marginTop:"5%"}}>{episode.releaseDate}</p>
            <h3 style={{marginTop:"0%"}}>
                <a target="_blank" href={serie.link} title = {serie.title} style={{color:'black'}}>
                    {`${serie.title} Ep.${episode.numEpisode}`}
                </a>
            </h3>
        </div>
    </div>
            )
}

export default Block;