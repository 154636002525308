import React, { useEffect, useState } from "react";
import BlogRow from "./Utils/BlogRow";
import style from "../../Serie/EpisodesBlock/Utils/EpisodeBlock.css";
//import EpsiodeBlock from "./Utils/EpsiodeBlock";

const BlogPage = (props) =>{
    const {
        episodes,
        lastId,
        lastPage,
        lastPagePosts
    } = props;
    const [isLoading, setLoading] = useState(true);
    const [ids, setIds] = useState([]);
    const updateIds = () => {
        let tempIds = []
        for (let i = 0; i<9; i++){
            tempIds.push(lastId+i);
        }
        setIds(tempIds)
        setLoading(false);
    }
    useEffect(()=> updateIds(), [lastId]);

    if (isLoading) {
        return (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}>Loading</div>
      );
    }
    let rows = 3;
    let episodeRows=[];
    if (lastPage) {
        rows = Math.ceil(lastPagePosts/3);
        for (let i=0; i<(rows-1)*3; i=i+3){
            episodeRows.push([episodes[ids[i]], episodes[ids[i+1]], episodes[ids[i+2]]])
        }
        let remaining = lastPagePosts%3;
        if (remaining !== 0) {
            let tempRow = [episodes[ids[lastPagePosts-remaining]]];
            if (remaining > 1) {
                tempRow[rows-1].push(episodes[ids[lastPagePosts-remaining+1]])
            }
            episodeRows.push(tempRow);
        } else {
            episodeRows.push([episodes[ids[lastPagePosts-3]], episodes[ids[lastPagePosts-2]], episodes[ids[lastPagePosts-1]]])
        }
    } else {
        for (let i=0; i<=(rows-1)*3; i = i+3){
            episodeRows.push([episodes[ids[i]], episodes[ids[i+1]], episodes[ids[i+2]]])
        }
    }
    if (episodes !== undefined) {
        return (
            <div style={{backgroundColor:'rgb(212,212,212)'}}>
                <BlogRow episodes = {episodeRows[0]}></BlogRow>
                {rows > 1 && <BlogRow episodes = {episodeRows[1]}></BlogRow>}
                {rows > 2 && <BlogRow episodes = {episodeRows[2]}></BlogRow>}
            </div>
        )
    }
}

export default BlogPage;