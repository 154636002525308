import React, { useEffect, useState } from "react";
import { flushSync } from 'react-dom';
//import EpsiodeBlock from "./Utils/EpsiodeBlock";
import Header from "./../Header/Header";
import Footer from "../Footer/Footer";
import BlogPage from "./BlogPage/BlogPage";
import BlogCategories from "./Utils/BlogCategories";
import { useParams } from "react-router-dom";
import { getEpisodesFromCategory } from "../Utils/utils";

const MyBlog = () =>{
    const BLOG_CATEGORIES = [
        "all",
        "videos",
        "images",
        "exclusive",
        "dares",
        "public"
    ]
    let { category } = useParams();
    if (!(BLOG_CATEGORIES.includes(category))) {
        category = "all";
    }
    const [isLoading, setLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [lastId, setLastId] = useState(0);
    const [pages, setPages] = useState([1,2,3,4,5]);
    const [episodes, setEpisodes] = useState();

    const fetchData = () => {
        getEpisodesFromCategory(category).then((obj) => {
            setEpisodes(obj);
            setLoading(false);
        })};
    useEffect(()=> fetchData(), []);

    const calculatePages = (index) => {
        if (totalPages >= 5) {
            if (index < 4) {
                setPages([1,2,3,4,5])
            }else if (index > totalPages - 2) {
                setPages([totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages])
            } else {
                setPages([index - 2, index - 1, index, index + 1, index + 2])
            }

        } else {
            let tempList = [];
            for (let i=1; i <= totalPages; i++) {
                tempList.push(i);
            }
            setPages(tempList);
        }
    }

    const handlePrevClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            setLastId(lastId - 9);
            calculatePages(currentIndex - 1);
        }
      };
      
    const handleNextClick = () => {
        if (currentIndex <= totalPages) {
            setCurrentIndex(currentIndex + 1);
            calculatePages(currentIndex + 1);
            setLastId(lastId + 9);
        }
    };

    const handleChangePage = (page) => {
        setCurrentIndex(page);
        calculatePages(page);
        setLastId(0 + (9 * (page-1)));
    }

    if (isLoading) {
        return (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}>Loading</div>
      );
    }

    let totalPages = Math.ceil(episodes.length/9);
    let maxId = episodes.length;
    let lastPagePosts = episodes.length%9;
    return (
        <div style={{backgroundColor:'rgb(212,212,212)'}}>
            <Header current="myblog"/>
            <h1 style={{textAlign:"center"}}>My Blog</h1>
            <BlogCategories category={category}></BlogCategories>
            <BlogPage
                episodes={episodes}
                lastId={lastId}
                lastPage={currentIndex === totalPages ? true : false}
                lastPagePosts={lastPagePosts}
            ></BlogPage>
            <div style={{width:"20%", marginLeft:"40%"}}>
                {currentIndex > 1 && <button onClick={handlePrevClick}>Previous</button>}
                {true && pages.map((page) => {
                    if (page === currentIndex) {
                        return <>{page}</>
                    }
                    if (page <= totalPages) {
                        return <a onClick={() => handleChangePage(page)}> {page} </a>
                    }
                })}
                {currentIndex < totalPages && <button onClick={handleNextClick}>Next</button>}
            </div>
            <Footer />
        </div>
    )
}

export default MyBlog;