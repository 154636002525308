import series from "./Lists/SeriesList"
import episodes from "./Lists/EpisodesList"
import LINKS from "./links";

const requestOptionsGet = {
    method: 'GET',
    headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'X-Requested-With',
            },
};

const requestOptionsPost = {
    method: 'POST',
    headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'X-Requested-With',
            },
    body: null,
};

const convertObjectToSeries = (obj) => {
    const serie = {
        id: obj.id,
        title: obj.title,
        link: obj.link,
        description: obj.description,
        shortDesc: obj.shortDescription,
        summary: obj.summary,
        imgSrc: obj.imageSource,
        nextEpisode: obj.nextEpisode,
        futureEpisodes: obj.futureEpisodes.substring(1, obj.futureEpisodes.length -1).split('",'),
    }
    return serie;
}

const convertObjectToEpisode = (obj) => {
    const serie = {
        id: obj.id,
        name: obj.name,
        seriesId: obj.seriesId,
        season: obj.season,
        numEpisode: obj.episode,
        imgSrc: obj.imageSource,
        releaseDate: obj.releaseDate,
        description: obj.description,
        intro: obj.intro,
        imgDescriptions: obj.imageDescriptions.substring(1, obj.imageDescriptions.length -1).split('",'),
        numVideos: obj.numVideos,
        videoDescriptions: obj.videoDescriptions.substring(1, obj.videoDescriptions.length -1).split('",'),
        relatedSeriesIds: obj.relatedSeriesIds.substring(1, obj.relatedSeriesIds.length -1).split(','),
    }
    return serie;
}

async function getSeriesFromId(id) {
    return await fetch(`${LINKS.SERIES.getSeriesById}${id}`, requestOptionsGet)
        .then((res) => res.json())
        .then((obj => convertObjectToSeries(obj)));
}

const getSeriesFromLink = async (link) => {
    return await fetch(`${LINKS.SERIES.getSeriesByLink}${link}`, requestOptionsGet)
        .then((res) => res.json())
        .then((obj => convertObjectToSeries(obj)));
}

const getEpisodeFromId = async (id) => {
    return await fetch(`${LINKS.EPISODES.getEpisodeById}${id}`, requestOptionsGet)
        .then((res) => res.json())
        .then((obj => convertObjectToEpisode(obj)));
}

const getEpisodeFromSeason = async (season, numEpisode, seriesId) => {
    return await fetch(`${LINKS.EPISODES.getEpisodeBySeason}${season}/${numEpisode}/${seriesId}`, requestOptionsGet)
        .then((res) => res.json())
        .then((obj => convertObjectToEpisode(obj)));
}

const getEpisodesIdsFromSeriesId = async (id) => {
    let episodes = []
    await fetch(`${LINKS.EPISODES.getEpisodesFromSeries}${id}`, requestOptionsGet)
        .then((res) => res.json())
        .then((obj => {
            obj.forEach(episode => {
                episodes.push(convertObjectToEpisode(episode))
            });
        }));
    return episodes;
}

const getEpisodesFromCategory = async (category) => {
    let episodes = []
    await fetch(`${LINKS.CATEGORIES.getPostsCategory}${category}`, requestOptionsGet)
        .then((res) => res.json())
        .then((obj => {
            obj.forEach(episode => {
                episodes.push(convertObjectToEpisode(episode))
            });
        }));
    return episodes;
}

const setForm = async (body, link) => {
    requestOptionsPost.body = JSON.stringify(body, null, 2),
    await fetch(link, requestOptionsPost);
}

export {
    getSeriesFromId,
    getSeriesFromLink,
    getEpisodeFromId,
    getEpisodeFromSeason,
    getEpisodesIdsFromSeriesId,
    getEpisodesFromCategory,
    setForm,
}