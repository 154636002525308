import React from "react";
import Header from "./../Header/Header"
import Footer from "../Footer/Footer";
import image from "../Utils/images/RoastMe.jpg";
import VideoChannel from "../VideoChannel/VideoChannel";
import channelImports from "../Utils/videoChannels/channelImports";
import videoSeries from "../Utils/series/VideoImports";

const RoastMe = () =>{
    return (
        <div>
            <Header current="RoastMe"/>
            <img src={image} style={{width:'100%', height:'30vw'}}></img>
            <h1 style={{textAlign:'center', fontSize:'5vw'}}>Roast Me</h1>
            <p style={{width:'40%', textAlign:'center', marginLeft:'30%'}}>For a while, I only made videos where my penis was semi erect, or fully erect, in order to hide its real size. I'm done with that. I actually enjoy it now, and want to hear your best roasts. Don't limit it to just my penis though. Everything is in play, my butt, my butthole, the shape I'm in, my feet(if that's what you're into). Let me know how bad I am!</p>
            <VideoChannel channel={"ROAST_ME"}></VideoChannel>
            <Footer />
        </div>
    )
}

export default RoastMe;