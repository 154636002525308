import React from "react";
import style from "./InfoBlock.css"

const InfoBlock = (props) => {
    const {
        series
    } = props;
    return (
        <div className="info-block">
            <div style={{maxWidth:'60%'}}>
                <h1>{series.title}</h1>
                <p>{series.summary}</p>
            </div>
            <div style={{maxWidth:'15%'}}>
                <img style={{maxWidth:'100%'}} src={`../${series.imgSrc}`}/>
            </div>
        </div>
    );
}

export default InfoBlock;