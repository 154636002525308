import React from "react";
import ScheduleBlock from "./Schedule/ScheduleBlock";
import Header from "./../Header/Header"
import Banner from "./Banner/Banner";
import ImageAccordeon from "./ImageAccordeon/ImageAccordeon";
import Popular from "./Popular/Popular";
import SeriesSlider from "./SeriesSlider/SeriesSlider";
import Footer from "../Footer/Footer";

const Series = () =>{
    return (
        <div style={{backgroundColor:'rgb(212,212,212)'}}>
            <Header current="series"/>
            <Banner/>
            <ImageAccordeon/>
            <ScheduleBlock release={true} episodesIds={[49,50,51]}/>
            <Popular/>
            <SeriesSlider/>
            <ScheduleBlock release={false} episodesIds={[46,47,48]}/>
            <Footer />
        </div>
    )
}

export default Series