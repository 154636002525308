import React, { useEffect, useState } from "react";
import style from "./RelatedSerieBlock.css"
import { getSeriesFromId } from "../../../Utils/utils";

const RelatedSerieBlock = (props) =>{
    let { serieId } = props;
    const [isLoading, setLoading] = useState(true);
    const [serie, setSerie] = useState();
    const fetchData = () => {
        getSeriesFromId(serieId).then((obj) => {
        setSerie(obj);
        setLoading(false);
    })};
    useEffect(()=> fetchData(), []);
    if (isLoading) {
        return (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}>Loading</div>
      );
    }
    return (
        <div>
            <a target="_blank" href={`/${serie.link}`}>
                <div className="episode-container">
                    <div>
                        <a target="_blank" href={`/${serie.link}`}>
                            <img className="related-series-image" src={`../../${serie.imgSrc}`}/>
                        </a>
                    </div>
                    
                    
                </div>
                <div className="episode-info">
                    <h3 className="episode-header">
                        <a target="_blank" href={`/${serie.link}`} style={{color:'black'}}>
                            {serie.title}
                        </a>
                    </h3>
                </div>
            </a>
        </div>
    )
}

export default RelatedSerieBlock;