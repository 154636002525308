import React from "react";
import style from "./SideWidget.css"
import AboutShort from "./Utils/AboutShort";
import SubscribeFormShort from "./Utils/SubscribeFormShort";

const SideWidget = (props) =>{
    return (
        <div className="side-widget">
            <AboutShort />
            <SubscribeFormShort />
        </div>
    );
}

export default SideWidget;