import React, { useEffect, useState } from "react";
import { getSeriesFromId } from "./../../../Utils/utils";
import style from "./TextBlock.css"

const TextBlock = (props) => {
    const {
        seriesId,
    } = props;
    const [isLoading, setLoading] = useState(true);
    const [serie, setSerie] = useState();
    const fetchData = () => {
        getSeriesFromId(seriesId).then((obj) => {
        setSerie(obj);
        setLoading(false);
    })};
    useEffect(()=> fetchData(), []);
    if (isLoading) {
        return (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}>Loading</div>
      );
    }
    return (
        <div className="schedule-container">
            <h3>
                <a href={`/${serie.link}`} style={{color:'black'}}>
                    {serie.title}
                </a>
            </h3>
            <p>{serie.description}</p>
        </div>
    )
}

export default TextBlock;