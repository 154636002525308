import React from "react";
import Header from "./../Header/Header"
import Footer from "../Footer/Footer";
import Constants from "../Utils/Lists/Constants";
import img from "../Utils/images/4Image/3.jpeg";

const About = () =>{
    return (
        <div>
            <Header current="about" />
            <div style={{marginTop:'5%', width:'40%', backgroundColor:'rgb(230, 248, 167)', display:'inline-block', marginLeft:'10%'}}>
                <h1 style={{width:'80%', marginLeft:'10%'}}>
                    The guy behind the posts
                </h1>
                <p style={{width:'80%', marginLeft:'10%'}}>
                    {Constants.aboutMe}
                </p>
            </div>
            <div style={{marginTop:'5%', width:'40%', backgroundColor:'rgb(187,217,94)', display:'inline-block', marginRight:'10%', verticalAlign:'top'}}>
                <img src={img} style={{maxWidth:'100%'}}></img>
            </div>
            <Footer />
        </div>
    )
}

export default About;