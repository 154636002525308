import React from "react";
import FormFooter from "./Utils/FormFooter";

const Footer = () =>{
    return (
        <div style={{paddingTop:'2%'}}>
            <div style={{backgroundColor:'black'}}>
                <br/>
            </div>
            <FormFooter />
        </div>
    )
}

export default Footer;