import React from "react";
import Header from "./../Header/Header"
import Footer from "../Footer/Footer";
import VideoChannel from "../VideoChannel/VideoChannel";
import style from './LiveStreams.css'

const LiveStreams = () =>{
    return (
        <div style={{backgroundColor:"rgb(212, 212, 212)"}}>
            <Header current="LiveStreams"/>
            <div className="two-box-streams">
                <div className="title-container-streams">
                    <h1 className="title-streams">Live Streams</h1>
                </div>
                <div className="text-container-streams">
                    <p className="text-streams"><b><i><u>Next Live Stream:</u></i></b> January 3, 2023 @ 11:00AM</p>
                    <p className="text-streams"><b><i><u>Description:</u></i></b> Let's celebrate 100 twitter followers together with a live stream. Do you have any requests, anything you want done, join and leave a comment</p>
                    <p className="text-streams">This is the place where I do live streams to celebrate special occasions. Just live video, no editing. Unfortunately, I cannot let you add comments without signing in due to limitations with the software</p>
                </div>
            </div>
            <VideoChannel channel={"LIVE_STREAMS"}></VideoChannel>
            <Footer />
        </div>
    )
}

export default LiveStreams;