import React from "react";
import Header from "./../Header/Header"
import { useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import PostForm from "./PostForm/PostForm";

const PostCreate = (props) =>{
    return (
        <div>
            <div style={{backgroundColor:'rgb(212,212,212)'}}>
                <Header/>
            </div>
            <PostForm />
            <Footer />
        </div>
    )
}

export default PostCreate;