import video1 from './RoastMe/smallAfterShower.mp4'
import video2 from './RoastMe/smallAfterWorkout.mp4'
import video3 from './RoastMe/theErection.mp4'
import video4 from './Live Streams/live-100followerspecial.mp4'
import video5 from './Live Streams/live-MasturbateLive.mp4'

const channelImports = {
    ROAST_ME: [
        {
            title: "Small penis after shower",
            url: video1,
        },
        {
            title: "Small penis after workout",
            url: video2,
        },
        {
            title: "Getting an erection (still small)",
            url: video3,
        },
    ],
    LIVE_STREAMS: [
        {
            title: "100 Follower special",
            url: video4
        },
        {
            title: "Live Masturbation",
            url: video5
        },
    ],
}

export default channelImports;